import { Grid, Card, CardHeader, CardContent, CardActions, Typography, IconButton, Avatar, CardMedia, Stack, colors, Table, TableRow, TableCell, Tooltip, TableBody } from "@mui/material";
import olives from '../../images/olives.webp';
import vineyard from '../../images/vineyard.webp';
import almonds from '../../images/almonds.webp';
import { Delete, Download, Edit, EnergySavingsLeaf, EvStation, Search, Water } from "@mui/icons-material";
import { variables } from "../../styles/abstract/variables";
import { Link } from "react-router-dom";

function CarbonReportItem({ model, onDelete, onReportPreview }) {
  const getImage = (crop) => {
    switch (crop) {
      case 'Olives':
        return olives;
      case 'Vineyards':
        return vineyard;
      case 'Almonds':
        return almonds;
      default:
        return olives;
    }
  }

  const renderEmissions = () => {
    let fuelTotal = Math.round(model.fuel.totalEmission);
    let irrigationTotal = Math.round(model.irrigation.totalEmission);

    return (
      <Table>
        <TableBody>
          <TableRow>
            <Tooltip placement="top" title="Fuel&Energy Emissions"><TableCell align="center" sx={{ border: 'none', padding: '5px' }}><EvStation color="error" /></TableCell></Tooltip>
            <Tooltip placement="top" title="Irrigation Emissions"><TableCell align="center" sx={{ border: 'none', padding: '5px' }}><Water color="blue" /></TableCell></Tooltip>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={{ border: 'none', padding: '5px' }}>{fuelTotal} <span>kg(CO<sub>2</sub>)</span></TableCell>
            <TableCell align="center" sx={{ border: 'none', padding: '5px' }}>{irrigationTotal} <span>kg(CO<sub>2</sub>)</span></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card elevation={5}>
        <CardHeader
          sx={{
            color: variables.primary, '& .MuiCardHeader-content': {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }
          }}
          title={`${model.crop} - ${model.year}`}
          subheader={`${model.name} (${model.area.toFixed(2)} ha)`}
        />
        <CardMedia
          component="img"
          height="194"
          image={getImage(model.crop)}
          alt="Crop"
        />
        <CardContent>
          {renderEmissions()}
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Tooltip arrow title="Preview Report">
            <IconButton onClick={() => onReportPreview(model)} aria-label="add to favorites">
              <Search color="secondary" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Download Report">
            <IconButton aria-label="add to favorites">
              <Download color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Edit Report">
            <IconButton component={Link} to={`/dashboard/carbon-calculator/${model.id}`} aria-label="add to favorites">
              <Edit color="warning" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Delete Report">
            <IconButton onClick={() => { onDelete(model.id) }} color="error" aria-label="share">
              <Delete />
            </IconButton>
          </Tooltip>

        </CardActions>

      </Card>
    </Grid>
  );
}

export default CarbonReportItem;