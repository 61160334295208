import { Button, Card, CardActions, CardContent, Grid, Stack } from "@mui/material";
import ResultField from "./ResultField";
import ResultFuel from "./ResultFuel";
import ResultIrrigation from "./ResultIrrigation";
import { KeyboardArrowLeft, Save } from "@mui/icons-material";
import $data from "../../services/$data";
import {Redirect} from 'react-router-dom'
import { useState } from "react";
function Result({model, direct, prevStep, setModel, isEdit = false}) {
  const [redirect, setRedirect] = useState(false);
  const updateReport = async () => {
    let newModel = {...model};

    newModel.fuel.totalEmission = newModel.fuel.isDirect ? $data.calculateFuelEmissions(newModel.fuel.direct, direct) : $data.calculateFuelEmissions(newModel.fuel.estimated, direct);
    newModel.irrigation.totalEmission = newModel.irrigation.isVolumeOnly ? $data.calculateVolumeEmissions(newModel.irrigation.volumeOnly.events, newModel.area) : $data.calculateDepthDistanceEmissions(newModel.irrigation.depthDistance.events, newModel.area);
    try {
      const report = await $data.updateCCRecord(newModel);
      console.log(report)
      setRedirect(true);
    } catch (error) {
      console.log(error)
    }
  }
  
  const saveReport = async () => {
    let newModel = {...model};

    newModel.fuel.totalEmission = newModel.fuel.isDirect ? $data.calculateFuelEmissions(newModel.fuel.direct, direct) : $data.calculateFuelEmissions(newModel.fuel.estimated, direct);
    newModel.irrigation.totalEmission = newModel.irrigation.isVolumeOnly ? $data.calculateVolumeEmissions(newModel.irrigation.volumeOnly.events, newModel.area) : $data.calculateDepthDistanceEmissions(newModel.irrigation.depthDistance.events, newModel.area);
    try {
      const report = await $data.postCCRecords(newModel);
      console.log(report)
      setRedirect(true);
    } catch (error) {
      console.log(error)
    }
  }

  if(redirect) {
    return <Redirect to="/dashboard/carbon-reports" />
  }

  return (
    <Card elevation={5} style={{ borderRadius: 0, background: 'transparent' }}>
    <CardContent className="co-background" sx={{padding: '25px'}}>
      <Grid container spacing={4}>
        {<ResultField onUdateReport={updateReport} isEdit={isEdit} onSaveReport={saveReport} model={model} />}
        {<ResultFuel model={model.fuel} direct={direct} />}
        {<ResultIrrigation model={model.irrigation} skip={model.fuel.isDirect} area={model.area} direct={direct} />}
      </Grid>
    </CardContent>
    <CardActions style={{ padding: '0 16px 16px 16px', justifyContent: 'flex-end' }} className="co-background">
      <Stack sx={{ width: '100%' }} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
        <Button  onClick={prevStep} variant="contained" endIcon={<KeyboardArrowLeft />}>Previous</Button> 
        {!isEdit && <Button onClick={saveReport} variant="contained" startIcon={<Save />} color="warning">Save</Button>}
        {isEdit && <Button onClick={updateReport} variant="contained" startIcon={<Save />} color="warning">Update</Button>}
      </Stack>
    </CardActions>
  </Card>
  );
}

export default Result;