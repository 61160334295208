import { Button, ButtonGroup, Card, CardActions, CardContent, Divider, Grid, IconButton, Paper, Stack } from "@mui/material"
import SelectInput from "../ui/SelectInput";
import { Add, Delete, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import TextInput from "../ui/TextInput";
import { useState } from "react";

function FuelConsumption({ fieldOperations = [], model, setModel, direct, estimated, prevStep, nextStep, area, updateFuelModel, fuelModel }) {

  const [directModel, setDirectModel] = useState({
    consumption: 0,
    source: ''
  })

  const [estimatedModel, setEstimatedModel] = useState({
    source: '',
    num_of_operations: 1,
    num_of_bales: 1,
    operation: undefined,
    machine: undefined,
    soil_type: undefined,
    factor_value: 0,
  });

  const [estimatedRecords, setEstimatedRecords] = useState([]);
  const [directRecords, setDirectRecords] = useState([]);



  const switchConsumptionMode = (bool, mode) => {
    if (model.isDirect && mode == 'direct') return;

    if (!model.isDirect && mode == 'estimated') return;

    if (mode == 'direct') {
      setEstimatedRecords([]);
    } else {
      setDirectRecords([]);
    }
    setModel({ isDirect: bool })
  }

  const renderDirectConsumptionLabel = (source) => {
    if (source === '') return 'Consumption';
    let sourceObj = direct.filter(d => d.name === source)[0];
    if(!sourceObj) return 'Consumption';

    return `Consumption [${sourceObj.unit.indexOf('kWh') > -1 ? 'kWh' : 'L'}]`
  }

  const renderEstimatedConsumptionLabel = (source) => {
    if (source === '') return 'Consumption';
    let sourceObj = direct.filter(d => d.name === source)[0];
    if(!sourceObj) return 'Consumption';
    return `Consumption [${sourceObj.unit.indexOf('kWh') > -1 ? 'kWh' : 'L'}]`
  }

  const renderDirect = () => {
    return (
      <>
        <Grid item xs={6}>
          <SelectInput onChange={(e) => setDirectModel(current => ({ ...current, source: e.target.value }))} value={directModel.source} name={'Energy Source'} items={direct.map((e, i) => ({ value: e.name, label: e.name }))} />

        </Grid>
        <Grid item xs={6}>
          <TextInput type="number" value={directModel.consumption} onChange={(e) => setDirectModel(current => ({ ...current, consumption: e.target.value }))} label={renderDirectConsumptionLabel(directModel.source)} />
        </Grid>
      </>

    )
  }

  const onOperationChange = (e) => {
    setEstimatedModel(current => ({...current, machine: undefined, factor_value: undefined}))
    setEstimatedModel(current => ({...current, operation: e.target.value}))
  }

  const convertOperationToLabel = (val) => {
    return val.split('_').join(' ').charAt(0).toUpperCase() + val.split('_').join(' ').slice(1);
  }

  const onMachineChange = (e) => {
    let machine = e.target.value;
    let soil_type = estimatedModel.soil_type;
    if(soil_type) {
      let factor_value = estimated.filter(obj => obj.name === machine)[0][soil_type.toLocaleLowerCase()];

      setEstimatedModel(current => ({ ...current, machine, factor_value })); 

    } else {
      setEstimatedModel(current => ({ ...current, machine })); 

    }
  }

  const onSoilTypeChange = (e) => {
    let machine = estimatedModel.machine;
    let soil_type = e.target.value;
    if(machine) {

      let factor_value = estimated.filter(obj => obj.name === machine)[0][soil_type.toLocaleLowerCase()];

      setEstimatedModel(current => ({ ...current, machine, soil_type, factor_value })); 

    } else {
      setEstimatedModel(current => ({ ...current, soil_type })); 
    }


  }

  const renderEstimated = () => {
    return (
      <>
        <Grid item xs={12}>
          <SelectInput onChange={(e) => setEstimatedModel(current => ({ ...current, source: e.target.value }))} value={estimatedModel.source} name={'Energy Source'} items={direct.map((e, i) => ({ value: e.name, label: e.name }))} />
        </Grid>
        <Grid item xs={6}>
          <SelectInput items={fieldOperations.map((e, i) => ({ value: e, label: convertOperationToLabel(e)}))} value={estimatedModel.operation} onChange={onOperationChange} name={'Field operation'} />
        </Grid>
        <Grid item xs={6}>
          <SelectInput items={estimated.filter(e => e.machine_type === estimatedModel.operation).map((e, i) => ({ value: e.name, label: e.name }))} value={estimatedModel.machine} onChange={onMachineChange} name={'Machine Type'} />
        </Grid>
        <Grid item xs={6}>
          <SelectInput items={[{ value: 'Medium', label: 'Medium' }, { value: 'Coarse', label: 'Coarse' }, { value: 'Fine', label: 'Fine' }]} value={estimatedModel.soil_type} onChange={onSoilTypeChange} name={'Soil Type'} />
        </Grid>
        <Grid item xs={6}>
        <TextInput type="number" min={1} value={estimatedModel.num_of_operations} onChange={(e) => setEstimatedModel(current => ({ ...current, num_of_operations: e.target.value }))} label={'Number of Operations'} />        </Grid>
        <Grid item xs={6}>
        <TextInput type="number" min={1} value={estimatedModel.num_of_bales} onChange={(e) => setEstimatedModel(current => ({ ...current, num_of_bales: e.target.value }))} label={'Number of Bales'} helperText={'If no bales are produced, set to 1'} />        </Grid>
      </>
    );
  }

  const renderDirectRecords = () => {
    if (model.direct.length === 0) return <Grid item xs={12} textAlign="center">No records added</Grid>

    return model.direct.map((record, i) => {
      return (
        <Grid item xs={12} key={i}>
          <Stack component={Paper} elevation={5} sx={{ background: 'rgba(255,255,255,0.2)', padding: '5px 0 5px 10px' }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <span><strong>Source</strong>: {record.source} / <strong>{renderDirectConsumptionLabel(record.source)}</strong>: {record.consumption}</span>
            <IconButton onClick={() => {
              let newRecords = model.direct.filter((r, index) => index !== i);
              setModel({direct: newRecords});
            }} color="error"><Delete /></IconButton>
          </Stack>
        </Grid>
      )
    })
  }

  const renderEstimatedRecords = () => {
    if (model.estimated.length === 0) return <Grid item xs={12} textAlign="center">No records added</Grid>

    return model.estimated.map((record, i) => {
      return (
        <Grid item xs={12} key={i}>
          <Stack component={Paper} elevation={5} sx={{ background: 'rgba(255,255,255, 0.4)', padding: '5px 0 5px 10px' }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <span><strong>Source</strong>: {record.source} / <strong>{renderEstimatedConsumptionLabel(record.source)}</strong>: {record.consumption.toFixed(2)}</span>
            <IconButton onClick={() => {
              let newRecords = model.estimated.filter((r, index) => index !== i);
              setModel({estimated: newRecords});
            }} color="error"><Delete /></IconButton>
          </Stack>
        </Grid>
      )
    })
  }

  const addDirectRecord = () => {
    setModel({direct: [...model.direct, directModel]});
  }

  const isEstimatedValid = () => {
    let { num_of_bales, num_of_operations, factor_value, source } = estimatedModel;
    if(!num_of_bales || !num_of_operations || !factor_value || !area || !source) return false;
    return true;
  }

  const addEstimatedRecord = () => {
    let { num_of_bales, num_of_operations, factor_value } = estimatedModel;

    

    let consumption = num_of_bales * num_of_operations * factor_value * area;
    setModel({estimated: [...model.estimated, { ...estimatedModel, consumption }]});

  }

  const previous = () => {

  }

  return (
    <Card elevation={5} style={{ borderRadius: 0, background: 'transparent' }}>
      <CardContent className="co-background">
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <span>Select energy use type: </span>
          <ButtonGroup>
            <Button color="primary" onClick={() => switchConsumptionMode(true, 'direct')} variant={model.isDirect ? 'contained' : 'outlined'}>Direct Energy Use (Aggregated)</Button>
            <Button color="primary" onClick={() => switchConsumptionMode(false, 'estimated')} variant={model.isDirect ? 'outlined' : 'contained'}>Estimated Energy Use (per Operation)</Button>
          </ButtonGroup>
        </Stack>
        <Grid container spacing={2} sx={{ mt: '20px' }}>
          {model.isDirect && renderDirect()}
          {!model.isDirect && renderEstimated()}
        </Grid>


        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            {model.isDirect && <Button onClick={addDirectRecord} disabled={!directModel.source || !directModel.consumption} variant="contained" startIcon={<Add />}>Add direct energy use record</Button>}
            {!model.isDirect && <Button onClick={addEstimatedRecord} disabled={!isEstimatedValid()} variant="contained" startIcon={<Add />}>Add estimated energy use record</Button>}

          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {model.isDirect && renderDirectRecords()}
          {!model.isDirect && renderEstimatedRecords()}


        </Grid>
        <br /><br />
      </CardContent>
      <CardActions style={{ padding: '0 16px 16px 16px', justifyContent: 'flex-end' }} className="co-background">
        <Stack sx={{ width: '100%' }} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <Button onClick={prevStep} variant="contained" endIcon={<KeyboardArrowLeft />}>Previous</Button>
          <Button disabled={(model.isDirect && model.direct.length === 0) || (!model.isDirect && model.estimated.length === 0)} onClick={nextStep} variant="contained" endIcon={<KeyboardArrowRight />}>Next</Button> 
          
        </Stack>
      </CardActions>
    </Card>
  )
}

export default FuelConsumption