import { Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import { useContext, useState } from "react";
import $data from "../../services/$data";
import { DataContext } from "../DataContext";

function DeleteReport({open, onClose, reportId, onDelete}) {

  const {onShowSuccess} = useContext(DataContext);

	const [loading, setLoading] = useState(false);

	const [status, setStatus] = useState({
		error: {},
		success: null,
	})

	const onSubmit = async () => {
		setLoading(true);

		try {
			await onDelete(reportId);
			
      onShowSuccess('Report successfully deleted');
			onClose();

		} catch(e) {
      console.log(e)
			setStatus({...status, error: e});
		} finally {
			setLoading(false);
		}
	}

	return (
		<Dialog PaperProps={{className: 'co-background-white'}} open={open} onClose={onClose}>
			<DialogTitle>Delete report</DialogTitle>
			<DialogContent>
				<p className="co-text-primary text-bold">Are you sure that you want to delete selected carbon report?</p>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} style={{lineHeight: 'unset'}}>Cancel</Button>
				<Button onClick={onSubmit} style={{lineHeight: 'unset'}} variant="contained" color="error" startIcon={<Delete />}>DELETE</Button>
			</DialogActions>
			{loading && <LinearProgress color="primary" />}

		</Dialog>
	)
}

export default DeleteReport;