import { Save } from "@mui/icons-material";
import { Container, Grid, IconButton, Stack, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

function ResultField({ model, onSaveReport, onUpdateReport, isEdit }) {
  return (
    <>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Typography color="primary" variant="h5"><strong>Parcel Information</strong></Typography>
          {!isEdit && <Tooltip arrow title="Save report"><IconButton onClick={onSaveReport} color="warning"><Save fontSize="large" /></IconButton></Tooltip>}
          {isEdit && <Tooltip arrow title="Update report"><IconButton onClick={onUpdateReport} color="warning"><Save fontSize="large" /></IconButton></Tooltip>}
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{mb: '40px'}}>

        <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Country</Typography>
            <Typography color="primary" variant="h6">{model.country?.country || ' - '}</Typography>

          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography variant="h6">Name/Identifier</Typography>
              <Typography color="primary" variant="h6">{model.name}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography variant="h6">Area</Typography>
              <Typography color="primary" variant="h6">{model.area.toFixed(2)} ha</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography variant="h6">Year</Typography>
              <Typography color="primary" variant="h6">{model.year}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack>
              <Typography variant="h6">Crop</Typography>
              <Typography color="primary" variant="h6">{model.crop}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      </Grid>
    </>
  )
}

export default ResultField;