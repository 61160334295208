export const byCountry = {
  "Afghanistan": 0.128351,
  "Albania": 0.000000,
  "Algeria": 0.508179,
  "American Samoa": 0.728065,
  "Angola": 0.166970,
  "Antigua and Barbuda": 0.728065,
  "Argentina": 0.376172,
  "Armenia": 0.221997,
  "Aruba": 0.652898,
  "Australia": 0.595120,
  "Austria": 0.117334,
  "Azerbaijan": 0.479431,
  "Bahamas": 0.770892,
  "Bahrain": 0.510856,
  "Bangladesh": 0.596211,
  "Barbados": 0.707160,
  "Belarus": 0.445297,
  "Belgium": 0.125245,
  "Belize": 0.165898,
  "Benin": 0.552554,
  "Bermuda": 0.759169,
  "Bhutan": 0.000000,
  "Bolivia": 0.337921,
  "Bosnia and Herzegovina": 0.652587,
  "Botswana": 0.969676,
  "Brazil": 0.074096,
  "British Virgin Islands": 0.770892,
  "Brunei": 0.612617,
  "Bulgaria": 0.453500,
  "Burkina Faso": 0.530614,
  "Burundi": 0.257862,
  "Cambodia": 0.471811,
  "Cameroon": 0.214493,
  "Canada": 0.116000,
  "Cape Verde": 0.645398,
  "Cayman Islands": 0.748866,
  "Central African Republic": 0.000000,
  "Chad": 0.727765,
  "Chile": 0.361503,
  "China": 0.660804,
  "Colombia": 0.176893,
  "Comoros": 0.770892,
  "Congo": 0.409020,
  "Cook Islands": 0.385446,
  "Costa Rica": 0.000493,
  "Cote d'Ivoire": 0.353161,
  "Croatia": 0.240367,
  "Cuba": 0.712808,
  "Cyprus": 0.642290,
  "Czechia": 0.502774,
  "Democratic Republic of Congo": 0.000088,
  "Denmark": 0.139598,
  "Djibouti": 0.770892,
  "Dominica": 0.589506,
  "Dominican Republic": 0.623070,
  "East Timor": 0.770892,
  "Ecuador": 0.138051,
  "Egypt": 0.472777,
  "El Salvador": 0.073833,
  "Equatorial Guinea": 0.345911,
  "Eritrea": 0.750605,
  "Estonia": 0.530585,
  "Eswatini": 0.081067,
  "Ethiopia": 0.000563,
  "Falkland Islands": 0.385446,
  "Faroe Islands": 0.477219,
  "Fiji": 0.312566,
  "Finland": 0.095015,
  "France": 0.070552,
  "French Guiana": 0.238129,
  "French Polynesia": 0.517599,
  "Gabon": 0.322638,
  "Gambia": 0.770892,
  "Georgia": 0.121564,
  "Germany": 0.379949,
  "Ghana": 0.344450,
  "Gibraltar": 0.511000,
  "Greece": 0.337808,
  "Greenland": 0.178957,
  "Grenada": 0.740056,
  "Guadeloupe": 0.544781,
  "Guam": 0.731246,
  "Guatemala": 0.313290,
  "Guinea": 0.258655,
  "Guinea-Bissau": 0.770892,
  "Guyana": 0.753092,
  "Haiti": 0.667118,
  "Honduras": 0.289586,
  "Hong Kong": 0.692938,
  "Hungary": 0.232519,
  "Iceland": 0.000000,
  "India": 0.933549,
  "Indonesia": 0.707110,
  "Iran": 0.511770,
  "Iraq": 0.574543,
  "Ireland": 0.314133,
  "Israel": 0.565009,
  "Italy": 0.314316,
  "Jamaica": 0.515440,
  "Japan": 0.457733,
  "Jordan": 0.405581,
  "Kazakhstan": 0.767970,
  "Kenya": 0.062505,
  "Kiribati": 0.770892,
  "Kosovo": 0.916890,
  "Kuwait": 0.623962,
  "Kyrgyzstan": 0.132474,
  "Laos": 0.265112,
  "Latvia": 0.129095,
  "Lebanon": 0.698118,
  "Lesotho": 0.000000,
  "Liberia": 0.253711,
  "Libya": 0.586275,
  "Lithuania": 0.164254,
  "Luxembourg": 0.087093,
  "Macao": 0.336845,
  "Madagascar": 0.498989,
  "Malawi": 0.046880,
  "Malaysia": 0.614561,
  "Maldives": 0.716476,
  "Mali": 0.465108,
  "Malta": 0.451268,
  "Martinique": 0.576868,
  "Mauritania": 0.539624,
  "Mauritius": 0.702925,
  "Mexico": 0.405968,
  "Moldova": 0.473887,
  "Mongolia": 0.863488,
  "Montenegro": 0.459574,
  "Montserrat": 0.770892,
  "Morocco": 0.773844,
  "Mozambique": 0.092649,
  "Myanmar": 0.310654,
  "Namibia": 0.036349,
  "Nauru": 0.770892,
  "Nepal": 0.000000,
  "Netherlands": 0.309015,
  "New Caledonia": 0.729061,
  "New Zealand": 0.079960,
  "Nicaragua": 0.249019,
  "Niger": 0.758324,
  "Nigeria": 0.400333,
  "North Korea": 0.408801,
  "North Macedonia": 0.627511,
  "Norway": 0.008435,
  "Oman": 0.516971,
  "Pakistan": 0.432341,
  "Palestine": 0.596820,
  "Panama": 0.142219,
  "Papua New Guinea": 0.528872,
  "Paraguay": 0.000103,
  "Peru": 0.200661,
  "Philippines": 0.682465,
  "Poland": 0.707940,
  "Portugal": 0.166386,
  "Puerto Rico": 0.713786,
  "Qatar": 0.509666,
  "Reunion": 0.619934,
  "Romania": 0.278199,
  "Russia": 0.464078,
  "Rwanda": 0.301075,
  "Saint Helena": 0.770892,
  "Saint Kitts and Nevis": 0.735851,
  "Saint Lucia": 0.770892,
  "Saint Pierre and Miquelon": 0.770892,
  "Saint Vincent and the Grenadines": 0.589506,
  "Samoa": 0.527452,
  "Sao Tome and Principe": 0.715828,
  "Saudi Arabia": 0.594955,
  "Senegal": 0.584413,
  "Serbia": 0.670461,
  "Seychelles": 0.658988,
  "Sierra Leone": 0.038545,
  "Singapore": 0.502494,
  "Slovakia": 0.166383,
  "Slovenia": 0.252760,
  "Solomon Islands": 0.770892,
  "Somalia": 0.689745,
  "South Africa": 0.773467,
  "South Korea": 0.487551,
  "South Sudan": 0.746024,
  "Spain": 0.181109,
  "Sri Lanka": 0.571171,
  "Sudan": 0.289823,
  "Suriname": 0.396023,
  "Sweden": 0.015183,
  "Switzerland": 0.023685,
  "Syria": 0.595166,
  "Taiwan": 0.636024,
  "Tajikistan": 0.096911,
  "Tanzania": 0.345271,
  "Thailand": 0.527935,
  "Togo": 0.403923,
  "Tonga": 0.674530,
  "Trinidad and Tobago": 0.511561,
  "Tunisia": 0.495700,
  "Turkey": 0.423850,
  "Turkmenistan": 0.510779,
  "Turks and Caicos Islands": 0.770892,
  "Uganda": 0.010922,
  "Ukraine": 0.252085,
  "United Arab Emirates": 0.421608,
  "United Kingdom": 0.207408,
  "United States": 0.374589,
  "United States Virgin Islands": 0.748219,
  "Uruguay": 0.075624,
  "Uzbekistan": 0.496075,
  "Vanuatu": 0.660765,
  "Venezuela": 0.131241,
  "Vietnam": 0.437143,
  "Yemen": 0.566527,
  "Zambia": 0.109011,
  "Zimbabwe": 0.321272,
};