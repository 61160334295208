import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";

function ResultIrrigation({ model, direct, area, skip }) {

  const calculateVolume = (depth, area, percentage) => {
    try {
      let volume = parseFloat(depth) * parseFloat(area) * (parseFloat(percentage) / 100);

      if (isNaN(volume)) {
        return ' - '
      }

      return `${Math.round(volume)} liters`;
    } catch (e) {
      return ' - '
    }
  }
  const renderByVolume = () => {
    if(model.volumeOnly.events.length === 0) return null;
    return (
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Method of input</Typography>
            <Typography color="primary" variant="h6">By Volume</Typography>

          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {model.volumeOnly.events.map((event, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Paper key={index} sx={{ background: 'rgba(255,255,255,0.4)', padding: '20px', margin: '10px 0' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Event {index + 1}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Irrigation method</Typography>
                            <Typography color="primary" variant="h6">{event.method}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Power source</Typography>
                            <Typography color="primary" variant="h6">{event.fuel}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Irrigation depth</Typography>
                            <Typography color="primary" variant="h6">{event.depth} mm</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Irrigated area ({event.percentage}%)</Typography>
                            <Typography color="primary" variant="h6">{(area * event.percentage / 100).toFixed(2)} ha</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Estimated volume</Typography>
                            <Typography color="primary" variant="h6">{calculateVolume(event.depth, area, event.percentage)}</Typography>
                          </Stack>
                        </Grid>


                      </Grid>
                    </Paper>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    )
  }

  const renderByDepthDistance = () => {
    if(model.depthDistance.events.length === 0) return null;
    return (
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Method of input</Typography>
            <Typography color="primary" variant="h6">By Depth and Distance</Typography>

          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {model.depthDistance.events.map((event, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Paper key={index} sx={{ background: 'rgba(255,255,255,0.4)', padding: '20px', margin: '10px 0' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Event {index + 1}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Irrigation method</Typography>
                            <Typography color="primary" variant="h6">{event.method}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Power source</Typography>
                            <Typography color="primary" variant="h6">{event.fuel}</Typography>
                          </Stack>
                        </Grid>
                        {event.fuel === 'Diesel' && <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Power source type</Typography>
                            <Typography color="primary" variant="h6">{event.fuelType}</Typography>
                          </Stack>
                        </Grid>}
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Horizontal distance</Typography>
                            <Typography color="primary" variant="h6">{event.horizontalDistance} km</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Pumping depth</Typography>
                            <Typography color="primary" variant="h6">{event.boreholeDepth} m</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Irrigation depth</Typography>
                            <Typography color="primary" variant="h6">{event.depth} mm</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Irrigated area ({event.percentage}%)</Typography>
                            <Typography color="primary" variant="h6">{(area * (event.percentage / 100)).toFixed(2)} ha</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <Stack>
                            <Typography variant="h6">Estimated volume</Typography>
                            <Typography color="primary" variant="h6">{calculateVolume(event.depth, area, event.percentage)}</Typography>
                          </Stack>
                        </Grid>


                      </Grid>
                    </Paper>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    )
  }

  const renderByVolumeCalculation = () => {
    let total = $data.calculateVolumeEmissions(model.volumeOnly.events, area);
    if (total > 999) {
      total = `${(Math.round(total / 1000))} t`;
    } else {
      total = `${Math.round(total)} kg`;
    }
    return (
      <Grid item xs={12}>
        <Stack justifyContent="center" alignItems="center" sx={{ margin: '0 auto', border: `20px solid ${variables.primary}`, borderRadius: '100%', width: '250px', height: '250px' }}>
          <Typography textAlign="center" variant="h5">Irrigation Emissions</Typography>
          <Typography color="primary" variant="h4">{total}(CO<sub>2</sub>)</Typography>
        </Stack>
      </Grid>
    )
  }

  const renderByDepthDistanceCalculation = () => {
    let total = $data.calculateDepthDistanceEmissions(model.depthDistance.events, area);

    if (total > 999) {
      total = `${(Math.round(total / 1000))} t`;
    } else {
      total = `${Math.round(total)} kg`;
    }
    console.log("TOTAL EMISSION", total)
    return <Grid item xs={12}>
      <Stack justifyContent="center" alignItems="center" sx={{ margin: '0 auto', border: `20px solid ${variables.primary}`, borderRadius: '100%', width: '250px', height: '250px' }}>
        <Typography textAlign="center" variant="h5">Irrigation Emissions</Typography>
        <Typography color="primary" variant="h4">{total}(CO<sub>2</sub>)</Typography>
      </Stack>
    </Grid>
  }

  if(model.depthDistance.events.length === 0 && model.volumeOnly.events.length === 0) return null;

  return (
    <>
      <Grid item xs={12}>
        <Typography color="primary" variant="h5"><strong>Irrigation Emissions</strong></Typography>
      </Grid>
      <Grid item xs={12}>
        {model.isVolumeOnly && renderByVolume()}
        {!model.isVolumeOnly && renderByDepthDistance()}
      </Grid>
      <Grid item xs={12} sx={{ mb: '40px' }}>
        {model.isVolumeOnly && renderByVolumeCalculation()}
        {!model.isVolumeOnly && renderByDepthDistanceCalculation()}
      </Grid>
    </>
  );
}

export default ResultIrrigation;