import { Box, Button, ButtonGroup, Card, CardActions, CardContent, Divider, Grid, IconButton, Paper, Slider, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import SelectInput from "../ui/SelectInput";
import { Add, Delete, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import TextInput from "../ui/TextInput";
import { useEffect, useState } from "react";
import { variables } from "../../styles/abstract/variables";
import $carbon from "../../services/$carbon";

function FertiliserPesticides({ fertiliserProduction, nitrificationInhibitors, directUsage, globalWarming, model, setModel, prevStep, nextStep, area }) {
  console.table(directUsage);
  const [state, setState] = useState({
    fertiliser_type: undefined,
    manufactured: undefined,
    application_rate: undefined,
    overallP: undefined,
    factorP: undefined,
    inhibitors_applicable: false,
    inhibitors: false,
    customNPK: {
      n: 0,
      na: 0,
      nn: 0,
      nu: 0,
      p: 0,
      k: 0
    }

  });

  const updateFertiliserType = (e) => {

    let newModel = {
      ...state,
      fertiliser_type: e.target.value,
      manufactured: undefined,
      overallP: undefined,
      factorP: undefined,
      inhibitors_applicable: [
        'Calcium nitrate - 15.5% N',
        'Compose your own NPK',
        'Limestone - 55% CaCO3 / 29%CaO',
        'Muriate of potash / Potassium chloride - 60% K2O',
        'Phosphate/Rock Phosphate - 32% P2O5',
        'Polyhalite - 48% SO3 / 14% K2O / 6% MgO / 17% CaO',
        'Super phosphate - 21% P2O5',
        'Triple super phosphate - 48% P2O5',

      ].indexOf(e.target.value.fertiliser_label) === -1,
      inhibitors: false,
      customNPK: {
        n: 0,
        na: 0,
        nn: 0,
        nu: 0,
        p: 0,
        k: 0
      },
    }
    setState(newModel)
  }

  const updateManufactured = (e) => {
    let newModel = {
      ...state,
      manufactured: e.target.value,
      overallP: e.target.value.production_ef,
      factorP: state.fertiliser_type.fertiliser_label === 'Compose your own NPK' ? $carbon._calculateCustomNPK(state.customNPK, e.target.value.production_ef, e.target.value.region, fertiliserProduction.compose_your_own) : e.target.value.production_ef
    }
    setState(newModel)
  }

  const updateCustomNPK = (type, value) => {
    setState(current => ({
      ...current,
      customNPK: {
        ...current.customNPK,
        [type]: value,
      },
      factorP: $carbon._calculateCustomNPK({
        ...current.customNPK,
        [type]: value,
      }, state.overallP, state.manufactured?.region, fertiliserProduction.compose_your_own)

    }))
  }

  useEffect(() => {
    setState(current => ({
      ...current,
      customNPK: {
        ...current.customNPK,
        n: state.customNPK.na + state.customNPK.nn + state.customNPK.nu,
      }
    }));


  }, [state.customNPK.na, state.customNPK.nn, state.customNPK.nu]);

  //FULL CALCULATION
  useEffect(() => {
    if(state.application_rate !== undefined && state.factorP !== undefined) {
      let nitrogenPercentage = state.fertiliser_type.fertiliser_label === 'Compose your own NPK' ? state.customNPK.n/100 : state.fertiliser_type.percent_n/100;
      let limestonePercentage = state.fertiliser_type.fertiliser_label === 'Compose your own NPK' ? 0 : state.fertiliser_type.percent_caco3/100;
      let ureaPercentage = state.fertiliser_type.fertiliser_label === 'Compose your own NPK' ? 0 : state.fertiliser_type.percent_urea/100;
     
     //DIRECT n2o EMISSION
      let amountN = area * parseFloat(state.application_rate) * nitrogenPercentage;
      let amountL = area * parseFloat(state.application_rate) * limestonePercentage;
      let amountU = area * parseFloat(state.application_rate) * ureaPercentage;
      
      let emissionP = state.factorP * parseFloat(state.application_rate);
      
      let factorD = $carbon.getDirectFertiliserEmissionFactor('Warm Temperate Moist', state.fertiliser_type.direct_ef_class_key, directUsage);
      let molecularWeight = 44/28;
      let emissionD = amountN  * factorD * molecularWeight; 
      console.log('AMOUNT N:', amountN);
      console.log('EMISSION FACTOR DIRECT:', factorD)
      console.log('====================================');
      console.log('DIRECT EMISSION:', emissionD, 'kg(CO2)')
      console.log('====================================');
      let emissionInhibited = 0
      //NITRIFICATION INHIBITORS
      if(state.inhibitors) {
        let niFactor = 0.63;
        let nonNitrate = state.fertiliser_type.non_nitrane_n === 'NA' ? 0 : state.fertiliser_type.non_nitrane_n/100
        emissionInhibited = niFactor * nonNitrate;
        console.log('====================================');
        console.log('IHIBITED EMISSION:', emissionInhibited, 'kg(N2O)');
        console.log('====================================');

      }

      //INDIRECT n2o EMISSION
      let leach = amountN * ($carbon.getDryWetCondition('Warm Temperate Moist') === 'wet' ? 0.24 : 0) * 0.011 * molecularWeight; 
      let volatilisation = amountN * (state.fertiliser_type.ammonia_volatilisation_factor === 'NA' ? 0 : state.fertiliser_type.ammonia_volatilisation_factor) * ($carbon.getDryWetCondition('Warm Temperate Moist') === 'wet' ? 0.014 : 0.005) * molecularWeight;
      console.log('LEACH EMISSIONS:', leach, 'kg(N2O)')
      console.log('VOLATILISATION EMISSIONS:', volatilisation, 'kg(N2O)')

      let emissionIndirect = leach + volatilisation;
      console.log('====================================');
      console.log('INDIRECT EMISSION:', emissionIndirect, 'kg(N2O)');
      console.log('====================================');

      //LIMESTONE/UREA EMISSION
      let limestone = amountL * 0.12 * molecularWeight;
      let urea = amountU * 0.20 * molecularWeight;

      console.log('LIMESTONE EMISSION:', limestone, 'kg(CO2)');
      console.log('UREA EMISSION:', urea, 'kg(CO2)');

      let total = emissionP + (emissionD + emissionInhibited + emissionIndirect) * globalWarming.filter(obj => obj.ghg === 'N2O')[0].gwp + limestone + urea;

      console.log('====================================');
      console.log('TOTAL FERTILISATION EMISSION:', total, 'kg(CO2)');
      console.log('====================================');
    }
  }, [state.application_rate, state.factorP, state.inhibitors]);

  const getManufacturingList = (type) => {
    if (!type) return [];

    return [... new Set(fertiliserProduction.pre_defined_prods.filter(obj => obj.fertiliser_label === type.fertiliser_label))];
  }

  const renderCustomNPK = () => {
    return (
      <Grid item xs={12} sx={{ mb: '20px' }}>
        <Paper elevation={5} sx={{ background: 'transparent', width: 'calc(100% - 40px)', margin: '0 auto', padding: '20px', boxSizing: 'border-box' }}>
          <Table sx={{ '& td': { border: 'none' } }}>
            <TableBody>
              <TableRow>
                <TableCell sx={{ width: '180px', padding: '5px' }}>% N</TableCell>
                <TableCell sx={{ width: '50px', padding: '5px' }}>{state.customNPK.n}%</TableCell>
                <TableCell sx={{ padding: '5px' }}><Slider disabled min={0} max={100} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '5px 5px 5px 25px' }}>% N (as ammonium-N)</TableCell>
                <TableCell sx={{ padding: '5px 5px 5px 25px' }}>{state.customNPK.na}%</TableCell>
                <TableCell sx={{ padding: '5px 5px 5px 25px' }}><Slider value={state.customNPK.na} onChange={(evt, value) => updateCustomNPK('na', value)} min={0} max={100} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '5px 5px 5px 25px' }}>% N (as nitrate-N)</TableCell>
                <TableCell sx={{ padding: '5px 5px 5px 25px' }}>{state.customNPK.nn}%</TableCell>
                <TableCell sx={{ padding: '5px 5px 5px 25px' }}><Slider value={state.customNPK.nn} onChange={(evt, value) => updateCustomNPK('nn', value)} min={0} max={100} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '5px 5px 5px 25px' }}>% N (as urea-N)</TableCell>
                <TableCell sx={{ padding: '5px 5px 5px 25px' }}>{state.customNPK.nu}%</TableCell>
                <TableCell sx={{ padding: '5px 5px 5px 25px' }}><Slider value={state.customNPK.nu} onChange={(evt, value) => updateCustomNPK('nu', value)} min={0} max={100} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '5px' }}>% P2O5 or % P</TableCell>
                <TableCell sx={{ padding: '5px' }}>{state.customNPK.p}%</TableCell>
                <TableCell sx={{ padding: '5px' }}><Slider value={state.customNPK.p} onChange={(evt, value) => updateCustomNPK('p', value)} min={0} max={100} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ padding: '5px' }}>% K2O or % K</TableCell>
                <TableCell sx={{ padding: '5px' }}>{state.customNPK.k}%</TableCell>
                <TableCell sx={{ padding: '5px' }}><Slider value={state.customNPK.k} onChange={(evt, value) => updateCustomNPK('k', value)} min={0} max={100} /></TableCell>
              </TableRow>
            </TableBody>
          </Table>

        </Paper>

      </Grid>
    )

  }

  return (
    <Card elevation={5} style={{ borderRadius: 0, background: 'transparent' }}>
      <CardContent className="co-background">

        <Grid container spacing={2} sx={{ mt: '20px' }}>
          <Grid item xs={12} sm={10}>
            <SelectInput onChange={updateFertiliserType} items={nitrificationInhibitors.map(n => ({ value: n, label: n.fertiliser_label }))} value={state.fertiliser_type} name="Fertiliser Type" />
          </Grid>
          {state.fertiliser_type?.fertiliser_label === 'Compose your own NPK' && renderCustomNPK()}

          <Grid item xs={12} sm={6}>
            <SelectInput onChange={updateManufactured} items={getManufacturingList(state.fertiliser_type).map(obj => ({ value: obj, label: obj.region }))} value={state.manufactured} name="Manufactured In" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextInput value={state.application_rate} onChange={(e) => setState(current => ({ ...current, application_rate: e.target.value }))} type="number" label="Appliation rate (kg/ha)" />
          </Grid>
          {state.inhibitors_applicable && <Grid item xs={12} sm={6} md={4}>
            <SelectInput onChange={(e) => setState(current => ({...current, inhibitors: e.target.value}))} items={[{value: false, label: 'None'}, {value: true, label: 'Nitrification inhibitor'}]} value={state.nitrification} name="Emissions inhibitors" />
          </Grid>}

        </Grid>




        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            {/* {model.isDirect && <Button onClick={addDirectRecord} disabled={!directModel.source || !directModel.consumption} variant="contained" startIcon={<Add />}>Add direct energy use record</Button>}
            {!model.isDirect && <Button onClick={addEstimatedRecord} disabled={!isEstimatedValid()} variant="contained" startIcon={<Add />}>Add estimated energy use record</Button>} */}

          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* {model.isDirect && renderDirectRecords()} */}
          {/* {!model.isDirect && renderEstimatedRecords()} */}


        </Grid>
        <br /><br />
      </CardContent>
      <CardActions style={{ padding: '0 16px 16px 16px', justifyContent: 'flex-end' }} className="co-background">
        <Stack sx={{ width: '100%' }} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <Button onClick={prevStep} variant="contained" endIcon={<KeyboardArrowLeft />}>Previous</Button>
          {/* <Button disabled={(model.isDirect && model.direct.length === 0) || (!model.isDirect && model.estimated.length === 0)} onClick={nextStep} variant="contained" endIcon={<KeyboardArrowRight />}>Next</Button>  */}

        </Stack>
      </CardActions>
    </Card>
  )
}

export default FertiliserPesticides