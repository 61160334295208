import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import $data from "../../services/$data";
import { variables } from "../../styles/abstract/variables";

function ResultFuel({ model, direct }) {


  const renderDirectConsumptionLabel = (source) => {
    if (source === '') return 'Consumption';
    let sourceObj = direct.filter(d => d.name === source)[0];
    if (!sourceObj) return 'Consumption';

    return `Consumption [${sourceObj.unit.indexOf('kWh') > -1 ? 'kWh' : 'L'}]`
  }

  const renderEstimatedConsumptionLabel = (source) => {
    if (source === '') return 'Consumption';
    let sourceObj = direct.filter(d => d.name === source)[0];
    if (!sourceObj) return 'Consumption';
    return `Consumption [${sourceObj.unit.indexOf('kWh') > -1 ? 'kWh' : 'L'}]`
  }

  const renderDirectRecords = () => {
    if (model.direct.length === 0) return <Grid item xs={12} textAlign="center">No records added</Grid>

    return model.direct.map((record, i) => {
      return (
        <Grid item xs={12} key={i}>
          <Stack component={Paper} elevation={5} sx={{ background: 'rgba(255,255,255,0.2)', padding: '20px' }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Stack>
                  <Typography variant="h6">Power Source</Typography>
                  <Typography color="primary" variant="h6">{record.source}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack>
                  <Typography variant="h6">{renderDirectConsumptionLabel(record.source)}</Typography>
                  <Typography color="primary" variant="h6">{parseFloat(record.consumption).toFixed(2)}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      )
    })
  }

  const convertOperationToLabel = (val) => {
    return val.split('_').join(' ').charAt(0).toUpperCase() + val.split('_').join(' ').slice(1);
  }

  const renderEstimatedRecords = () => {
    if (model.estimated.length === 0) return <Grid item xs={12} textAlign="center">No records added</Grid>

    return model.estimated.map((record, i) => {
      console.log(record)
      return (
        <Grid item xs={12} key={i}>
          <Stack component={Paper} elevation={5} sx={{ background: 'rgba(255,255,255,0.2)', padding: '20px' }} direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Stack>
                  <Typography variant="h6">Power Source</Typography>
                  <Typography color="primary" variant="h6">{record.source}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack>
                  <Typography variant="h6">Field operation</Typography>
                  <Typography color="primary" variant="h6">{convertOperationToLabel(record.operation)}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack>
                  <Typography variant="h6">Machine type</Typography>
                  <Typography color="primary" variant="h6">{record.machine}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack>
                  <Typography variant="h6">Number of Operations</Typography>
                  <Typography color="primary" variant="h6">{record.num_of_operations}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack>
                  <Typography variant="h6">Number of Bales</Typography>
                  <Typography color="primary" variant="h6">{record.num_of_bales === 1 ? ' - ' : record.num_of_bales}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack>
                  <Typography variant="h6">{renderEstimatedConsumptionLabel(record.source)}</Typography>
                  <Typography color="primary" variant="h6">{parseFloat(record.consumption).toFixed(2)}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      )
    })
  }

  const renderCalculation = () => {
    let total = $data.calculateFuelEmissions(model.isDirect ? model.direct : model.estimated, direct)
    if (total > 999) {
      total = `${Math.round(total / 1000)} t`;
    } else {
      total = `${Math.round(total)} kg`;
    }
    return (
      <Grid item xs={12} sx={{ mt: '40px', mb: '40px' }}>
        <Stack justifyContent="center" alignItems="center" sx={{ margin: '0 auto', border: `20px solid ${variables.primary}`, borderRadius: '100%', width: '250px', height: '250px' }}>
          <Typography textAlign="center" variant="h5">Fuel & Energy Emissions</Typography>
          <Typography color="primary" variant="h4">{total}(CO<sub>2</sub>)</Typography>
        </Stack>
      </Grid>
    );
  }

  const renderContent = () => {


    return (
      <>
        <Grid item xs={12}>
          <Typography color="primary" variant="h5"><strong>Fuel & Energy Use</strong></Typography>
        </Grid>
        <Grid item xs={12}>
          <Container>
            <Grid container spacing={2}>


              {model.isDirect && <Grid item xs={12}>
                <Typography variant="h6">Method of input</Typography>
                <Typography color="primary" variant="h6">Direct Energy Use (Aggregated)</Typography>

              </Grid>}
              {!model.isDirect && <Grid item xs={12}>
                <Typography variant="h6">Method of input</Typography>
                <Typography color="primary" variant="h6">Estimated Energy Use (Per Operation)</Typography>

              </Grid>}
              {model.isDirect && renderDirectRecords()}
              {!model.isDirect && renderEstimatedRecords()}

              {renderCalculation()}
            </Grid>
          </Container>
        </Grid>
      </>
    )
  }



  return renderContent();
}

export default ResultFuel;