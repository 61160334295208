import GeoJSON from 'ol/format/GeoJSON';
import { fromLonLat } from 'ol/proj';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import $map from '../services/$map';
import { variables } from '../styles/abstract/variables';

class Parcel {
	static getFarmName(farm) {
		try {
			return `${farm.first_name} ${farm.last_name}`;
		} catch (error) {
			return 'Farm 1'
		}
	}

	static isInParcel(lon, lat, id, parcels) {
		try {
			let ps = parcels.filter(parcel => id === parcel.getId())[0].getFeature().getGeometry();
			if (ps.intersectsCoordinate(fromLonLat([lon, lat]))) {
				return true
			} else {
				return false
			}
		} catch (error) {
			console.log(error)
			return false
		}
	}

	constructor(model = {}, feature) {
		this.id = model.pk;
		this.name = model.name;
		this.area = model.area;
		this.agroforestry = model.af_status;
		this.crops = model.crop_history;
		this.farm = model.farm;
		this.feature = feature ? new GeoJSON().readFeature(feature) : null;
	}

	getId() {
		return this.id;
	}

	getName() {
		return this.name || 'Uknown';
	}

	getArea() {
		return this.area.toFixed(2);
	}

	isAgroforestry() {
		return this.agroforestry;
	}

	getFeature() {
		return this.feature;
	}

	getProperties() {
		return this.feature ? this.feature.getProperties() : {};
	}

	getFarm() {
		return { ...this.farm }
	}

	getCentroid() {
		return this.feature.getGeometry().getInteriorPoint().getCoordinates();
	}

	zoomTo(view) {
		view.fit(this.feature.getGeometry(), {padding: [125, 125, 125, 125]})
	}

}

export default Parcel;