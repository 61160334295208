import { useContext, useEffect, useState } from "react";
import $data from "../../services/$data";
import { Card, CardContent, CircularProgress, Container, Divider, Fab, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import WidgetTitle from "../widgets/WidgetTitle";
import WidgetDescription from "../widgets/WidgetDescription";
import { variables } from "../../styles/abstract/variables";
import { Add, Error } from "@mui/icons-material";
import { Link } from "react-router-dom";
import SelectInput from "../ui/SelectInput";
import CarbonReportItem from "./CarbonReportItem";
import DeleteReport from "./DeleteConfirm";
import { AppContext } from "../../AppContext";
import ReportPreview from "./ReportPreview";

function CarbonReports(props) {
  const { user } = useContext(AppContext);
  const [state, setState] = useState({
    records: [],
    loading: true,
    error: false
  });

  const [deleteModalID, setDeleteModalID] = useState(null)
  const [reportPreview, setReportPreview] = useState(null)

  const [filter, setFilter] = useState({
    project: 'all',
    parcel: 'all',
    year: 'all',
    crop: 'all'
  })

  const getData = async () => {
    setState(current => ({ ...current, loading: true }))
    try {
      let records = await $data.getCCRecords();
      setState({ records, loading: false, error: false });
    } catch (error) {
      setState({ records: [], loading: false, error: true });
    }
  }

  const deleteReport = async (id) => {
    try {
      const deleted = await $data.deleteCCRecord(id);
      getData();
    } catch (error) {

    }
  }

  const filterReports = () => {
    let filtered = [...state.records];

    if (user.user_type === 3) {
      if (filter.project !== 'all') {
        filtered = filtered.filter(obj => obj.project_name === filter.project);
      }
    }

    if (filter.parcel !== 'all') {
      filtered = filtered.filter(obj => obj.name === filter.parcel);
    }

    if (filter.crop !== 'all') {
      filtered = filtered.filter(obj => obj.crop === filter.crop);
    }

    if (filter.year !== 'all') {
      filtered = filtered.filter(obj => obj.year === filter.year);
    }

    return filtered;

  }

  useEffect(() => {
    getData();
  }, []);

  const generateFilters = () => {
    let cropList = [...new Set(state.records.map(obj => obj.crop))];
    let yearList = [...new Set(state.records.map(obj => obj.year))];

    return (
      <Grid item xs={12}>
        <Card elevation={5} style={{ borderRadius: 0, background: 'transparent' }}>
          <CardContent className="co-background">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography color="primary" variant="h6">Filter by:</Typography>
              </Grid>
              {user.user_type === 3 &&
                <Grid item xs={12} sm={4} md={3}>
                  <SelectInput items={
                    [...new Set(state.records.map(c => ({ value: c.project_name, label: c.project_name }))), { value: 'all', label: 'All' }]
                  } value={filter.project} name="Project" onChange={(evt) => setFilter(current => ({ ...current, project: evt.target.value, parcel: 'all' }))} />
                </Grid>
              }

              {user.user_type === 3 &&
                <Grid item xs={12} sm={4} md={3}>
                  <SelectInput items={
                    [...new Set(state.records
                      .filter(obj => filter.project === 'all' || obj.project_name === filter.project)
                      .map(c => ({ value: c.name, label: `${c.name}(${c.area.toFixed(2)}ha)` }))), { value: 'all', label: 'All' }]

                  } value={filter.parcel} name="Parcel" onChange={(evt) => setFilter(current => ({ ...current, parcel: evt.target.value }))} />
                </Grid>
              }
              {user.user_type !== 3 &&
                <Grid item xs={12} sm={4} md={3}>
                  <SelectInput items={[...state.records.map(c => ({ value: c.name, label: `${c.name}(${c.area.toFixed(2)}ha)` })), { value: 'all', label: 'All' }]} value={filter.parcel} name="Parcel" onChange={(evt) => setFilter(current => ({ ...current, parcel: evt.target.value }))} />
                </Grid>
              }
              <Grid item xs={12} sm={4} md={3}>
                <SelectInput items={[...yearList.map(c => ({ value: c, label: c })), { value: 'all', label: 'All' }]} value={filter.year} name="Year" onChange={(evt) => setFilter(current => ({ ...current, year: evt.target.value }))} />
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <SelectInput items={[...cropList.map(c => ({ value: c, label: c })), { value: 'all', label: 'All' }]} value={filter.crop} name="Crop" onChange={(evt) => setFilter(current => ({ ...current, crop: evt.target.value }))} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

    )
  }

  const renderReports = () => {
    if (state.records.length === 0) {
      return (
        <Grid item xs={12}>
          <Card elevation={5} style={{ borderRadius: 0, background: 'transparent' }}>
            <CardContent className="co-background">
              <Grid container spacing={2}>
                <Grid item xs={12} textAlign="center"><Typography>There are no generated carbon emission assessments</Typography></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )
    }

    let filtered = filterReports();

    if (filtered.length === 0) {
      return (
        <Grid item xs={12}>
          <Card elevation={5} style={{ borderRadius: 0, background: 'transparent' }}>
            <CardContent className="co-background">
              <Grid container spacing={2}>
                <Grid item xs={12} textAlign="center"><Typography>There are no carbon emission assessments that match filter parameters</Typography></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )
    }

    let reports = filtered.map((record, i) => {
      return <CarbonReportItem onReportPreview={(model) => setReportPreview(model)} onDelete={() => setDeleteModalID(record.id)} key={i} model={record} />
    });

    return (
      <Grid item xs={12}>
        <Card elevation={5} style={{ borderRadius: 0, background: 'transparent' }}>
          <CardContent className="co-background">
            <Grid container spacing={2}>
              {reports}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const renderContent = () => {
    if (state.loading) {
      return (
        <Grid textAlign="center" item xs={12}>
          <CircularProgress />
        </Grid>
      )
    }

    if (state.error) {
      return (
        <Grid textAlign="center" item xs={12}>
          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            <Error fontSize="large" color="error" />
            <Typography color="error" variant="h5">
              Error occured while trying to pull reports from the database
            </Typography>
          </Stack>
        </Grid>
      )
    }

    return (
      <>
        {generateFilters()}
        {renderReports()}
      </>
    );
  }

  return (
    <Container sx={{ mt: '30px', pb: '30px' }}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <WidgetTitle text="Carbon Emission Assesments" />
            <Tooltip arrow title="New Assessment">
              <Fab component={Link} to="/dashboard/carbon-calculator/new" size="small" color="primary" aria-label="add"><Add /></Fab>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack className="only-laptop" direction="row" alignItems="center" justifyContent="space-between">
            <WidgetDescription text="" />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider color="primary" light style={{ background: variables.primary, height: '3px' }} />
        </Grid>
        {renderContent()}

      </Grid>
      <DeleteReport open={Boolean(deleteModalID)} reportId={deleteModalID} onClose={() => setDeleteModalID(null)} onDelete={deleteReport} />
      {Boolean(reportPreview) && <ReportPreview open={Boolean(reportPreview)} model={reportPreview} onClose={() => setReportPreview(false)} onSubmit={() => {}} />}
    </Container>
  );
}

export default CarbonReports;