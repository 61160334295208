import { useEffect, useRef, useState } from "react";
import { View } from "ol";
import { fromLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import OSM from 'ol/source/OSM';
import olMap from 'ol/Map';
import VectorImageLayer from "ol/layer/VectorImage";
import VectorSource from "ol/source/Vector";
import { Button } from "@mui/material";
import { variables } from "../../styles/abstract/variables";

function ReportMap({ model, ready, onSetReady }) {
  const mapInstance = useRef();
  const initMap = () => {

    mapInstance.current = new olMap({
      pixelRatio: window.devicePixelRatio < 1 ? 1 : window.devicePixelRatio,
      target: document.querySelector('#map'),
      interactions: [],
      layers: [
        new TileLayer({
          source: new OSM()
        }),
        new VectorImageLayer({
          source: new VectorSource({
            features: [model.parcelObject.getFeature()]
          })
        })
      ],
      view: new View({
        zoom: 4,
        center: fromLonLat([25, 40])
      })

    });

    model.parcelObject.zoomTo(mapInstance.current.getView())

    mapInstance.current.once('rendercomplete', () => {
      const mapCanvas = document.createElement('canvas');
      const size = mapInstance.current.getSize();
      mapCanvas.width = size[0];
      mapCanvas.height = size[1];
      const mapContext = mapCanvas.getContext('2d');
      Array.prototype.forEach.call(
        mapInstance.current.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
        function (canvas) {
          if (canvas.width > 0) {
            const opacity =
              canvas.parentNode.style.opacity || canvas.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            let matrix;
            const transform = canvas.style.transform;
            if (transform) {
              // Get the transform parameters from the style's transform matrix
              matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(',')
                .map(Number);
            } else {
              matrix = [
                parseFloat(canvas.style.width) / canvas.width,
                0,
                0,
                parseFloat(canvas.style.height) / canvas.height,
                0,
                0,
              ];
            }
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix,
            );
            const backgroundColor = canvas.parentNode.style.backgroundColor;
            if (backgroundColor) {
              mapContext.fillStyle = backgroundColor;
              mapContext.fillRect(0, 0, canvas.width, canvas.height);
            }
            mapContext.drawImage(canvas, 0, 0);
          }
        },
      );
      mapContext.globalAlpha = 1;
      mapContext.setTransform(1, 0, 0, 1, 0, 0);

      setTimeout(() => {
        mapInstance.current.updateSize();
        onSetReady(mapCanvas.toDataURL());

      }, 100)
    });
  }

  useEffect(() => {
    initMap();


    return () => {
      mapInstance.current.setTarget(null)
    }
  }, [])

  if (ready) {
    return (
      <div style={{position: 'relative'}}>
        <img width="100%" style={{ boxShadow: '1px 0px 5px 1px rgba(0,0,0, 0.3)' }} src={ready} />
        <a href={model.kml || ('https://earth.google.com/web?url=' + ('https://drive.google.com/file/d/1vnvCCcUPZqLWnU4GDOUlRLJnS32iVTuV/view?usp=sharing'))} target="_blank" style={{marginTop: '20px', color: variables.primary, cursor: 'pointer', fontWeight: 'bold', display: 'block'}}> &#128269; Check imagery in Google Earth</a>   
      </div>
    );
  }

  return <div id="map" style={{ height: '400px', boxShadow: '1px 0px 5px 1px rgba(0,0,0, 0.3)' }}></div>
}

export default ReportMap;