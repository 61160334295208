const conditions = {
  'Tropical Montane': 'uknown',
  'Tropical Wet': 'wet',
  'Tropical Moist': 'wet',
  'Tropical Dry': 'dry',
  'Warm Temperate Moist': 'wet',
  'Warm Temperate Dry': 'dry',
  'Cool Temperate Moist': 'wet',
  'Cool Temperate Dry': 'dry',
  'Boreal Moist': 'wet',
  'Boreal Dry': 'dry',
  'Polar Moist': 'wet',
  'Polar Dry': 'dry',
}

class $carbon {

  getDryWetCondition(climateZone) {
    if(conditions[climateZone]) {
      return conditions[climateZone]
    }

    return 'unknown'
  }

  getFullDirectFactorName(type) {
    switch(type) {
      case 'synthetic': return 'Synthetic fertiliser (EF1SN)';
      case 'Other': return 'Other N applications (EF1ON)';
      case 'SheepOther': return 'Sheep and ‘other animals’ (EF3PRP, CPP)';
      case 'CattlePoultryPigs': return 'Cattle (dairy, nondairy and buffalo), poultry and pigs (EF3PRP, CPP)';
      default: return 'Other N applications (EF1ON)';
    }
  }

  getDirectFertiliserEmissionFactor(climateZone, type, table) {
    if(type === 'SheepOther') {
      return table.filter(obj => obj.disaggregation === 'all')[0].value;
    }

    try {
      let zone = this.getDryWetCondition(climateZone);
      let fullName = this.getFullDirectFactorName(type);
      console.log(zone);
      console.log(fullName)
      let result = table.filter(obj => obj.emission_factor === fullName && zone === obj.disaggregation)[0].value
      return result;
    } catch(e) {
      console.log(e);
    }

  }

  _calculateFertiliserProduction() {

  }

  _calculateCustomNPK(customNPK, overallP, region, table) {
    try {
      let rg = table.filter(obj => region.indexOf(obj.region) > -1)[0];

      let final = overallP + 
        (customNPK.na/100) * (rg ? rg.amonia : 1) +
        (customNPK.nn/100) * (rg ? rg.nitric_acid : 1) +
        (customNPK.nu/100) * (rg ? rg.urea : 1) +
        (customNPK.p/100) * (rg ? rg.p2o5 : 1) +
        (customNPK.k/100) * (rg ? rg.k2o : 1)
      
      console.log(final)
      return final;
    } catch(e) {
      console.log(e)
      return undefined;
    }


  }

  _calculateDirectN2O() {

  }

  _calculateNitrificationIhibitors() {

  }

  _calculateIndirectN2O() {

  }

  _calculateLimingAndUrea() {

  }

  calculateFertiliserEmissions(model) {

  }
}

export default new $carbon();