import { Close, Download } from "@mui/icons-material";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react"
import ReportTemplate from "./ReportTemplate";
import $data from "../../services/$data";
import wrapReportHtml from "./ReportHtmlWrapper";

function ReportPreview({ model, open, onClose, onSubmit }) {
  console.log(model)
  const downloadRef = useRef();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  
  const [loading, setLoading] = useState(true);


  const collectData = async () => {
    try {
      let photos = await $data.getPhotos();
      let parcel = await $data.getParcel(model.parcel_id);
      model.photos  = photos.filter(obj => Boolean(obj.parcel))
      model.parcelObject = parcel;
    } catch(e) {

    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    collectData();
  }, []);

  const renderReport = () => {
    if(loading) return (
      <Grid container sx={{mt: '40px'}}>
        <Grid textAlign="center" item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    )

    return <ReportTemplate model={model} />
  }

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography variant="h6">Report Preview</Typography>
          <IconButton color="secondary" onClick={onClose}><Close /></IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {renderReport()}
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={onClose} style={{ lineHeight: 'unset' }} color="error">Cancel</Button>
        <Button onClick={downloadPDF} style={{ lineHeight: 'unset' }} variant="contained" color="primary" startIcon={<Download />}>Download</Button>
      </DialogActions> */}
      {loadingSubmit && <LinearProgress color="primary" />}
      <a ref={downloadRef}></a>
    </Dialog>
  )
}

export default ReportPreview;