import { Button, ButtonGroup, Card, CardActions, CardContent, Divider, Grid, IconButton, Paper, Slider, Stack, Typography } from "@mui/material"
import SelectInput from "../ui/SelectInput";
import { Add, Delete, Info, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import TextInput from "../ui/TextInput";
import { useState } from "react";
import $data from "../../services/$data";
import { byCountry } from "../../config/electricityFactors";
import { variables } from "../../styles/abstract/variables";

const conversionFactors = {
  'diesel (100% mineral diesel)': 10.68,
  'diesel (average biofuel blend)': 10.61,
  'biodiesel me': 9.57,
  'petrol (100% mineral petrol)': 9.69,
  'petrol (average biofuel blend)': 9.55,
  'bioethanol': 6.55,
}

function IrrigationConsumption({ model, setModel, area, volumeOnly, countryCode, prevStep, nextStep, direct, skip = false, country }) {
  console.log(volumeOnly)
  const [volumeModel, setVolumeModel] = useState({
    method: undefined,
    depth: 0,
    fuel: undefined,
    percentage: undefined,
    factor: undefined,
  })

  const [estimatedModel, setEstimatedModel] = useState({
    source: '',
    num_of_operations: 1,
    num_of_bales: 1,
    operation: undefined,
    soil_type: undefined,
    factor_value: 0,
  });

  const [estimatedRecords, setEstimatedRecords] = useState([]);
  const [directRecords, setDirectRecords] = useState([]);

  const methods = [
    'Drip', 'Sprinkler', 'Surface',
  ]

  const fuels = [
    'Diesel', 'Electricity',
  ]

  const depthDistanceMethods = [
    'Centre pivot', 'Rain gun, sprinkler', 'Flooding', 'Drip irrigation'
  ]

  const switchConsumptionMode = (bool, mode) => {
    if (model.isVolumeOnly && mode == 'isVolumeOnly') return;

    if (!model.isVolumeOnly && mode == 'depthDistance') return;


    setModel({ isVolumeOnly: bool })
  }

  const onUpdateVolumeModel = (newModel) => {
    if (newModel.hasOwnProperty('method') && model.volumeOnly.fuel) {
      let factor = $data.getEmissionFactorByCountry(countryCode, model.volumeOnly.fuel, newModel.method, volumeOnly)
      newModel.factor = factor;
    }
    if (newModel.hasOwnProperty('fuel') && model.volumeOnly.method) {
      let factor = $data.getEmissionFactorByCountry(countryCode, newModel.fuel, model.volumeOnly.method, volumeOnly)
      newModel.factor = factor;
    }
    setModel({ volumeOnly: { ...model.volumeOnly, ...newModel } });
  }

  const onUpdateDepthDistanceModel = (newModel) => {
    if (newModel.hasOwnProperty('method') && model.depthDistance.fuel) {
      let factor = volumeOnly.irrigation_methods_energy.filter(m => newModel.method === m.irrigation_method)[0][model.depthDistance.fuel.toLowerCase()];
      newModel.emission_factor = factor;
      if (model.depthDistance.fuel === 'Electricity') {
        newModel.factor = byCountry[country];
        newModel.fuelType = undefined;
      } else {
        newModel.factor = undefined;
      }
    }
    if (newModel.hasOwnProperty('fuel') && model.depthDistance.method) {
      let factor = volumeOnly.irrigation_methods_energy.filter(m => model.depthDistance.method === m.irrigation_method)[0][newModel.fuel.toLowerCase()];
      newModel.emission_factor = factor;

      if (newModel.fuel === 'Electricity') {
        newModel.factor = byCountry[country];
        newModel.fuelType = undefined;
      } else {
        newModel.factor = undefined;
      }
    }

    if (newModel.hasOwnProperty('fuelType') && model.depthDistance.fuel && newModel.fuelType) {
      let factor = generatePowerSourceOptions(model.depthDistance.fuel).filter(m => m.name === newModel.fuelType)[0].emissions_factor;
      let conversionFactor = 1;
      if (model.depthDistance.fuel === 'Diesel') {
        conversionFactor = conversionFactors[newModel.fuelType.toLowerCase()]
        newModel.factor = factor;
      }


      newModel.conversionFactor = conversionFactor;
    }

    setModel({ depthDistance: { ...model.depthDistance, ...newModel } });
  }

  const calculateVolume = (depth, area, percentage) => {
    try {
      let volume = parseFloat(depth) * parseFloat(area) * (parseFloat(percentage) / 100);

      if (isNaN(volume)) {
        return ' - '
      }

      return `${Math.round(volume)} liters`;
    } catch (e) {
      return ' - '
    }
  }

  const renderVolume = () => {
    return (
      <>
        <Grid item xs={6}>
          <SelectInput required name="Select irrigation method" onChange={(e) => onUpdateVolumeModel({ method: e.target.value })} items={methods.map(m => ({ value: m, label: m }))} value={model.volumeOnly.method} />

        </Grid>
        <Grid item xs={6}>
          <SelectInput required name="Select power source" onChange={(e) => onUpdateVolumeModel({ fuel: e.target.value })} items={fuels.map(m => ({ value: m, label: m }))} value={model.volumeOnly.fuel} />
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <span>Percentage of irrigated area (%)</span>
            <Slider value={model.volumeOnly.percentage} min={0} max={100} valueLabelDisplay="on" onChange={(evt, val) => setModel({ volumeOnly: { ...model.volumeOnly, percentage: val } })} />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <TextInput required type="number" value={model.volumeOnly.depth} onChange={(e) => setModel({ volumeOnly: { ...model.volumeOnly, depth: e.target.value } })} label="Irrigation depth [mm]" />

        </Grid>
        <Grid item xs={12}>
          <Typography>Estimated volume [L]: {calculateVolume(model.volumeOnly.depth, area, model.volumeOnly.percentage)}</Typography>
        </Grid>
        <Grid textAlign="center" item xs={12}>
          <Button disabled={!isVolumeValid()} variant="contained" onClick={() => {
            let newModel = {...model.volumeOnly};
            delete newModel.events;
            setModel({ volumeOnly: { ...model.volumeOnly, events: [...model.volumeOnly.events, model.volumeOnly] } })
          }} startIcon={<Add />}>Add irrigation event</Button>
        </Grid>
      </>

    )
  }

  const generateDieselOptions = () => {

    if (model.depthDistance.fuel === 'Diesel') {
      return direct.filter(obj => (obj.name.toLowerCase().indexOf('diesel') > -1 && obj.name.indexOf('HVO') === -1) || obj.name.toLowerCase().indexOf('petrol') > -1 || obj.name.toLowerCase().indexOf('bioethanol') > -1);
    }
    return [];

  }

  const generateElectricityOptions = () => {
    if (model.depthDistance.fuel === 'Electricity') {
      return direct.filter(obj => obj.unit.indexOf('kWh') > -1);
    }
    return [];
  }

  const generatePowerSourceOptions = (type) => {
    console.log(type)
    if (type === 'Diesel') {
      return generateDieselOptions()
    }

    if (type === 'Electricity') {
      return generateElectricityOptions()
    }

    return [];
  }

  const renderDepthDistance = () => {
    return (
      <>
        <Grid item xs={6}>
          <SelectInput required name="Select irrigation method" onChange={(e) => onUpdateDepthDistanceModel({ method: e.target.value })} items={depthDistanceMethods.map(m => ({ value: m, label: m }))} value={model.depthDistance.method} />

        </Grid>
        <Grid item xs={6}>
          <SelectInput required name="Select power source" onChange={(e) => onUpdateDepthDistanceModel({ fuel: e.target.value })} items={fuels.map(m => ({ value: m, label: m }))} value={model.depthDistance.fuel} />
        </Grid>
        <Grid item xs={6}>
          <TextInput required type="number" value={model.depthDistance.horizontalDistance} onChange={(e) => onUpdateDepthDistanceModel({ horizontalDistance: e.target.value })} label="Horizontal distance [km]" />

        </Grid>
        <Grid item xs={6}>
          <TextInput required type="number" value={model.depthDistance.boreholeDepth} onChange={(e) => onUpdateDepthDistanceModel({ boreholeDepth: e.target.value })} label="Pumping depth [m]" />

        </Grid>

        <Grid item xs={6}>
          <Stack>
            <span>Percentage of irrigated area (%)</span>
            <Slider value={model.depthDistance.percentage} min={0} max={100} valueLabelDisplay="on" onChange={(evt, val) => onUpdateDepthDistanceModel({ percentage: val })} />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <TextInput required type="number" value={model.depthDistance.depth} onChange={(e) => onUpdateDepthDistanceModel({ depth: e.target.value })} label="Irrigation depth [mm]" />
        </Grid>
        {model.depthDistance.fuel === 'Diesel' && <Grid item xs={6}>
          <SelectInput required name="Select power source type" onChange={(e) => onUpdateDepthDistanceModel({ fuelType: e.target.value })} items={generatePowerSourceOptions(model.depthDistance.fuel).map(m => ({ label: m.name, value: m.name }))} value={model.depthDistance.fuelType} />
        </Grid>}
        <Grid item xs={12}>
          <Typography>Estimated volume [L]: {calculateVolume(model.depthDistance.depth, area, model.depthDistance.percentage)}</Typography>
        </Grid>
        <Grid textAlign="center" item xs={12}>
          <Button disabled={!isDepthDistanceValid()} variant="contained" onClick={() => {
            let newModel = {...model.depthDistance};
            delete newModel.events;
            setModel({ depthDistance: { ...model.depthDistance, events: [...model.depthDistance.events, newModel] } })
          }} startIcon={<Add />}>Add irrigation event</Button>
        </Grid>
      </>
    )
  }


  const isVolumeValid = () => {
    if (model.volumeOnly.method && model.volumeOnly.fuel && model.volumeOnly.depth && model.volumeOnly.percentage) {
      return true
    } return false;
  }

  const isDepthDistanceValid = () => {
    let { method, fuel, emission_factor, horizontalDistance, boreholeDepth, depth, fuelType, conversionFactor, factor } = model.depthDistance;

    let checkFuel = false;

    if (fuel === 'Diesel') {
      checkFuel = Boolean(fuel && fuelType);
    } else {
      checkFuel = Boolean(fuel)
    }

    if (
      method && emission_factor && horizontalDistance && boreholeDepth && depth && checkFuel && conversionFactor && factor
    ) return true;

    return false
  }

  const renderVolumeEvents = () => {
    if (model.volumeOnly.events.length === 0) {
      return (
        <Grid item textAlign="center" xs={12}>
          <Typography>No irrigation events added</Typography>
        </Grid>
      );
    }

    return model.volumeOnly.events.map((event, index) => {
      return (
        <Grid item xs={12} key={index}>
          <Paper key={index} sx={{ background: 'rgba(255,255,255,0.4)', padding: '10px', margin: '10px 0' }}>
            <Typography variant="h6">Event {index + 1}</Typography>
            <Stack direction="row" alignItems={'start'} justifyContent="space-between" spacing={2}>
              <Typography>
                <span>Irrigation Method: <strong style={{ color: variables.primary }}>{event.method}</strong></span>,&nbsp;
                <span>Power Source: <strong style={{ color: variables.primary }}>{event.fuel}</strong></span>, &nbsp;
                <span>Irrigation Depth: <strong style={{ color: variables.primary }}>{event.depth} mm</strong></span>,  &nbsp;
                <span>Irrigated Area({event.percentage}%): <strong style={{ color: variables.primary }}>{(area * (event.percentage / 100)).toFixed(2)} ha</strong></span>,  &nbsp;
                <span>Estimated Volume: <strong style={{ color: variables.primary }}>{calculateVolume(event.depth, area, event.percentage)}</strong></span>,  &nbsp;
              </Typography>
              <IconButton color="error" onClick={() => {
                let events = model.volumeOnly.events;
                events.splice(index, 1);
                setModel({ volumeOnly: { ...model.volumeOnly, events } })
              }}><Delete /></IconButton>
            </Stack>
          </Paper>
        </Grid>
      )
    })
  }

  const renderDepthDistanceEvents = () => {
    if (model.depthDistance.events.length === 0) {
      return (
        <Grid item textAlign="center" xs={12}>
          <Typography>No irrigation events added</Typography>
        </Grid>
      );
    }

    return model.depthDistance.events.map((event, index) => {
      return (
        <Grid item xs={12} key={index}>
          <Paper key={index} sx={{ background: 'rgba(255,255,255,0.4)', padding: '10px', margin: '10px 0' }}>
            <Typography variant="h6">Event {index + 1}</Typography>
            <Stack direction="row" alignItems={'start'} justifyContent="space-between" spacing={2}>
              <Typography>
                <span>Irrigation Method: <strong style={{ color: variables.primary }}>{event.method}</strong></span>,&nbsp;
                <span>Power Source: <strong style={{ color: variables.primary }}>{event.fuel}</strong></span>, &nbsp;
                {event.fuel === 'Diesel' && <span>Power Source Type: <strong style={{ color: variables.primary }}>{event.fuelType}</strong>, &nbsp;</span>}
                <span>Horizontal Distance: <strong style={{ color: variables.primary }}>{event.horizontalDistance} km</strong></span>,  &nbsp;
                <span>Pumping Depth: <strong style={{ color: variables.primary }}>{event.boreholeDepth} m</strong></span>,  &nbsp;
                <span>Irrigation Depth: <strong style={{ color: variables.primary }}>{event.depth} mm</strong></span>,  &nbsp;
                <span>Irrigated Area({event.percentage}%): <strong style={{ color: variables.primary }}>{(area * (event.percentage / 100)).toFixed(2)} ha</strong></span>,  &nbsp;
                <span>Estimated Volume: <strong style={{ color: variables.primary }}>{calculateVolume(event.depth, area, event.percentage)}</strong></span>,  &nbsp;
              </Typography>
              <IconButton color="error" onClick={() => {
                let events = model.depthDistance.events;
                events.splice(index, 1);
                setModel({ depthDistance: { ...model.depthDistance, events } })
              }}><Delete /></IconButton>
            </Stack>
          </Paper>
        </Grid>
      )
    })
  }

  return (
    <Card elevation={5} style={{ borderRadius: 0, background: 'transparent' }}>
      <CardContent className="co-background">
        {skip && <Grid container spacing={4}>
          <Grid item xs={12}>
            <Stack direction="row" sx={{ padding: '20px' }} justifyContent="center" alignItems="center" spacing={2}>

              <Info color="warning" /><Typography color={'rgb(237, 108, 2)'} variant="h6"> When the amount of fuel or energy used is provided via Direct Energy Use, you should skip this section. </Typography>
            </Stack>
          </Grid>

        </Grid>}
        <>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <span>Select input method: </span>
            <ButtonGroup>
              <Button color="primary" onClick={() => switchConsumptionMode(true, 'isVolumeOnly')} variant={model.isVolumeOnly ? 'contained' : 'outlined'}>By Volume</Button>
              <Button color="primary" onClick={() => switchConsumptionMode(false, 'depthDistance')} variant={model.isVolumeOnly ? 'outlined' : 'contained'}>By depth and distance</Button>
            </ButtonGroup>
          </Stack>
          <Grid container spacing={2} sx={{ mt: '20px' }}>
            {model.isVolumeOnly && renderVolume()}
            {!model.isVolumeOnly && renderDepthDistance()}
          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              {/* {model.isDirect && <Button onClick={addDirectRecord} disabled={!directModel.source || !directModel.consumption} variant="contained" startIcon={<Add />}>Add direct energy use record</Button>}
            {!model.isDirect && <Button onClick={addEstimatedRecord} disabled={!isEstimatedValid()} variant="contained" startIcon={<Add />}>Add estimated energy use record</Button>} */}

            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* {model.isDirect && renderDirectRecords()}
          {!model.isDirect && renderEstimatedRecords()} */}
            {model.isVolumeOnly && renderVolumeEvents()}
            {!model.isVolumeOnly && renderDepthDistanceEvents()}

          </Grid>
        </>
        <br /><br />

      </CardContent>
      <CardActions style={{ padding: '0 16px 16px 16px', justifyContent: 'flex-end' }} className="co-background">
        <Stack sx={{ width: '100%' }} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <Button onClick={prevStep} variant="contained" endIcon={<KeyboardArrowLeft />}>Previous</Button>
          <Button disabled={((model.isVolumeOnly && model.volumeOnly.events.length === 0) || (!model.isVolumeOnly && model.depthDistance.events.length === 0)) && !skip} onClick={nextStep} variant="contained" endIcon={<KeyboardArrowRight />}>Finalize</Button>

        </Stack>
      </CardActions>
    </Card>
  )
}

export default IrrigationConsumption;