import { useContext, useEffect, useState } from "react";
import { DataContext } from "../DataContext";
import $data from "../../services/$data";
import { Button, Card, CardActions, CardContent, CircularProgress, Container, Grid, Stack } from "@mui/material";
import { ArrowRight, KeyboardArrowRight } from "@mui/icons-material";
import TextInput from "../ui/TextInput";
import moment from "moment";
import SelectInput from "../ui/SelectInput";
import { AppContext } from "../../AppContext";

function FieldInformation(props) {
  const { user } = useContext(AppContext);

  const [state, setState] = useState({
    parcels: props.parcels,
    loading: false
  });
  console.log(props);
  // const [model, setModel] = useState({
  //   name: props.model?.name,
  //   area: props.model?.area,
  //   year: props.model?.year,
  //   crop: props.model?.crop
  // })

  const generateYears = () => {
    let initialVal = moment().format('YYYY');
    let arr = [];
    for (let i = 0; i < 50; i++) {

      if (i === 0) {
        arr.push({ value: initialVal, label: initialVal.toString() })
      }

      initialVal = moment(initialVal).subtract(1, 'years').format('YYYY');
      arr.push({ value: initialVal, label: initialVal.toString() })
    }
    return arr
  }

  const generateCrops = () => {
    const crops = ['Olives', 'Almonds', 'Vineyards'];

    return crops.map(c => ({ value: c, label: c }))
  }

  const setCountry = async (parcel) => {
    try {
      let country = await $data.getCountryForParcel(parcel);
      props.updateModel({ country: country });
    } catch (e) {
      console.log(e)
    }
  }


  const isValid = () => {
    if (!props.model.name || !props.model.area || !props.model.year || !props.model.crop) {
      return false;
    }

    return true;
  }

  const nextStep = () => {
    if (!isValid()) {
      return;
    }

    props.nextStep();

  }

  const renderContent = () => {
    if (state.loading) {
      return (
        <Grid sx={{ mt: '40px' }} item xs={12} textAlign="center">
          <CircularProgress />
        </Grid>
      )
    }

    return (
      <Grid item xs={12}>
        <Card elevation={5} style={{ borderRadius: 0, background: 'transparent' }}>
          <CardContent className="co-background" sx={{ padding: '40px' }}>
            <Grid container spacing={2}>
              {user.user_type === 3 && (
                <Grid item xs={12}>
                  <SelectInput required onChange={(e) => {
                    let project = e.target.value;
                    props.getParcelsForProject(project.id);
                    props.updateModel({ project_id: project.id, project_name: project.name, parcel_id: undefined, name: undefined, country: {}, area: 0 });
                  }} name={'Select project'} value={props.projects.filter(p => p.id === props.model.project_id)[0]} items={props.projects.map(p => ({ label: `${p.name}`, value: p }))} />
                </Grid>
              )
              }
              <Grid item xs={12}>
                <SelectInput required onChange={(e) => {
                  let parcel = e.target.value;
                  setCountry(parcel);
                  console.log(parcel)
                  props.updateModel({ parcel_id: parcel.getId(), name: parcel.getName(), area: parcel.area });
                }} name={'Select parcel'} value={props.parcels.filter(p => p.getId() === props.model.parcel_id)[0]} items={props.parcels.map(p => ({ label: `${p.getName()} (${p.getArea()}ha)`, value: p }))} />
              </Grid>
              <Grid item xs={8}>
                <TextInput sx={{ '& input': { cursor: 'not-allowed' } }} focused InputProps={{ readOnly: true }} label="Parcel name" value={props.model.name || ' - '} />
              </Grid>
              <Grid item xs={4}>
                <TextInput sx={{ '& input': { cursor: 'not-allowed' } }} focused InputProps={{ readOnly: true }} label="Area (ha)" type="number" value={props.model.area} />
              </Grid>

              <Grid item xs={6}>
                <SelectInput required onChange={(e) => props.updateModel({ year: e.target.value })} value={props.model.year} name={'Year'} items={generateYears()} />
              </Grid>

              <Grid item xs={6}>
                <SelectInput required onChange={(e) => props.updateModel({ crop: e.target.value })} value={props.model.crop} name={'Crop'} items={generateCrops()} />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ padding: '0 16px 16px 16px', justifyContent: 'flex-end' }} className="co-background">
            <Button onClick={nextStep} disabled={!isValid()} variant="contained" endIcon={<KeyboardArrowRight />}>Next</Button>
          </CardActions>
        </Card>
      </Grid>
    )
  }

  return (
    <Container>
      <Grid container spacing={2}>
        {renderContent()}
      </Grid>
    </Container>
  );
}

export default FieldInformation;