import axios from 'axios';
import URL from '../config/urls';
import moment from 'moment';
import Project from '../models/Project';
import Parcel from '../models/Parcel';
import { toLonLat } from 'ol/proj';

const tags = [
  'No-till',
  'Reduced or minimum tillage',
  'Cover crops',
  'Crop rotation',
  'Mulching',
  'Agroforestry and silvopasture',
  'Hedgerows, flower strips, shrubs',
  'Composting',
  'Biochar',
  'Holistically managed grazing',
  'Animal integration into crop production',
  'Perennial cropping',
];

class $data {

  createFarm(model) {
    return axios.post(URL.FARMS, model)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  updateFarm(model, id) {
    return axios.patch(URL.FARMS + `${id}/`, model)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  deleteFarm(id) {
    return axios.delete(URL.FARMS + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  getProjects(page, limit = 10) {
    return axios.get(URL.PROJECTS + `?limit=${limit}`)
      .then(response => {
        return {
          ...response.data,
          results: response.data.results.map(project => new Project(project))
        }
      })
      .catch(err => {
        throw err.message;
      })
  }

  createProject(model) {
    return axios.post(URL.PROJECTS, model)
      .then(response => {
        return new Project(response.data);
      })
      .catch(err => {
        throw err.message;
      })
  }

  updateProject(model) {
    console.log(model)
    return axios.put(URL.PROJECTS + `${model.id}/`, model)
      .then(response => {
        return new Project(response.data);
      })
      .catch(err => {
        throw err.message;
      })
  }


  deleteProject(id) {
    return axios.delete(URL.PROJECTS + `${id}/`)
      .then(response => {
        return response.data
      })
      .catch(err => {
        throw err.message;
      })
  }

  getFarms(projectId) {
    return axios.get(URL.FARMS + '?limit=999999&project_id=' + projectId)
      .then(result => result.data.results)
      .catch(err => { throw err.message })
  }

  getParcel(id) {
    return axios.get(URL.PARCELS_FARMER + `${id}/`)
      .then(result => {
        return new Parcel({...result.data.properties}, result.data);
      })
      .catch(e => {
        throw e;
      })
  }

  getParcels(projectId, isFarmer) {
    if (isFarmer) {
      return axios.get(URL.PARCELS_FARMER)
        .then(response => {
          if (!response.data?.features) {
            return []
          }
          return response.data.features.map(f => new Parcel({ ...f.properties }, f));
        })
        .catch(err => {
          throw err.message;
        })
    }
    return axios.get(URL.PARCELS.replace('__ID__', projectId))
      .then(response => {
        if (!response.data?.features) {
          return []
        }
        return response.data.features.map(f => new Parcel({ ...f.properties }, f));
      })
      .catch(err => {
        throw err.message;
      })
  }

  getPhotos(projectId) {
    let filter = projectId ? `?project_id=${projectId}` : '';

    return axios.get(URL.PHOTOS + filter)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getSamples(projectId) {
    let filter = projectId ? `?project_id=${projectId}` : '';
    console.log(projectId)
    return axios.get(URL.SAMPLES + filter)
      .then(result => this.formatSamples(result.data, Boolean(projectId)))
      .catch(err => { throw err.message })
  }

  formatSamples(data, isPD) {
    console.log(isPD)
    try {

      if (!data) return [];
      console.log('NIJE NO DATA');
      //USERS
      if (Object.keys(data).length === 0) return [];
      console.log('NIJE PRAZNO');

      let collection = Object.values(data);


      if (!collection) return [];

      let formated = [...collection];
      if (isPD) {
        formated = [];
        collection.map(arr => {
          arr.map(obj => {
            Object.values(obj).map(o => {
              o.map(item => {
                formated.push(item);
              })
            })
          })
        });

        console.log(formated);
        return formated

      } else {
        console.log('SAMPLES')
        console.log(collection)
        let samples = [];
        collection.map(coll => {
          Object.values(coll).map(c => {
            c.map(obj => {
              samples.push(obj)
            })
          })
        })
        console.log(samples);
        return samples
      }




    } catch (error) {
      console.log(error)
      return []
    }

  }

  importParcels(files) {
    return axios.post(URL.UPLOAD_PARCELS, files)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  createParcel(model) {
    return axios.post(URL.PARCEL, model)
      .then(result => new Parcel(result.data.properties, result.data))
      .catch(err => {
        console.log(err);
        throw err.message;
      })
  }

  editParcel(model, id) {
    console.log(model)
    return axios.patch(URL.PARCEL + `${id}/`, model)
      .then(result => new Parcel(result.data.properties, result.data))
      .catch(err => {
        console.log(err);
        throw err.message;
      })
  }

  deleteParcel(id) {
    return axios.delete(URL.PARCEL + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err.message });
  }

  getDatesS2(projectId, isFarmer, farmerId) {
    if (isFarmer) {
      return axios.get(URL.DATES_S2_FARMER.replace('__ID__', farmerId))
        .then(response => {
          return response.data;
        })
        .catch(err => {
          throw err.message;
        })
    }

    return axios.get(URL.DATES_S2.replace('__ID__', projectId))
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err.message;
      })
  }

  getVerification(parcelId) {
    return axios.get(URL.VERIFICATION + `${parcelId}/`)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err.message;
      })
  }

  getDeclaredReport(model) {
    return axios.post(URL.DECLARED_REPORT, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getDetectionReport(model) {
    return axios.post(URL.DETECTION_REPORT, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getApplicabilityReport(model) {
    return axios.post(URL.APPLICABILITY_REPORT, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  downloadPDF(htmlString) {
    console.log(htmlString)
    return axios.post(URL.GENERATE_PDF, { html_string: htmlString }, { responseType: 'blob' })
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  requestFarmer(model) {
    return axios.post(URL.REQUEST_FARMERS, model)
      .then(result => result.data)
      .catch(err => { throw err.message })
  }

  getFarmers() {
    return axios.get(URL.REQUEST_FARMERS + '?limit=9999999')
      .then(result => result.data.results)
      .catch(err => { throw err.message })
  }

  getParcelsForFarmer(id) {
    return axios.get(URL.FARMER_PARCELS.replace('__ID__', id))
      .then(response => {
        if (!response.data?.features) {
          return []
        }
        return response.data.features.map(f => new Parcel({ ...f.properties }, f));
      })
      .catch(err => { throw err.message })
  }

  addParcelsForFarmer(project, model) {
    return axios.post(URL.ADD_FARMRE_PARCELS.replace('__ID__', project), model)
      .then(result => result.data)
      .catch(err => { throw err })
  }

  disconnectFarmer(id) {
    return axios.delete(URL.REQUEST_FARMERS + `${id}/`)
      .then(result => result.data)
      .catch(err => { throw err })
  }

  getFuelFactors() {
    return axios.get(URL.FUEL_FACTORS)
      .then(result => result.data)
      .catch(err => { throw err });
  }

  formatFuelOperations(estimatedFactors) {
    let operations = [...new Set(estimatedFactors.map(obj => obj.machine_type))];

    return {
      operations, estimatedFactors
    }
  }

  getIrrigationFactors() {
    return axios.get(URL.IRRIGATION_FACTORS)
      .then(result => {
        console.log(result.data)
        return { ...result.data, irrigation_emmission_factor: result.data.irrigation_emmission_factor.filter(obj => obj.region.indexOf('GLO') > -1 || obj.region.indexOf('ES') > -1 || obj.region.indexOf('IT') > -1) }
      })
      .catch(err => { console.log(err); throw err });
  }

  getFertiliserTables() {
    return axios.all([
      axios.get(URL.FERTILISER_PRODUCTION),
      axios.get(URL.DIRECT_EMISSIONS),
      axios.get(URL.GLOBAL_WARMING),
      axios.get(URL.INDIRECT_EMISSIONS),
      axios.get(URL.NITRIFICATION),
      axios.get(URL.PLANT_PROTECTION),
    ])
    .then(axios.spread((fp,df, gw, ie, ni, pp) => {
      console.log(gw.data)
      return {
        fertiliserProduction: fp.data,
        directUsage: df.data.filter(d => d.emission_factor.indexOf('rice') === -1),
        globalWarming: gw.data,
        indirectEmissions: ie.data,
        nitrificationInhibitors: ni.data,
        plantProtection: pp.data
      }
    }))
    .catch(err => {throw err})
  }

  

  calculateFertiliserEmissions(model) {
    let Eproduction, Edirect, Einhibited, Eindirect, gw, Eurea, Eliming;
    try {
      let {} = model;



      return Eproduction + (Edirect + Einhibited + Eindirect) * gw + Eurea + Eliming;
    } catch(e) {
      console.log(e);

      return ' - ';
    }
    
   
  }

  getCCRecords() {
    return axios.get(URL.CC_RECORDS)
      .then(result => {
        return result.data.map(obj => {
          let formated = { ...obj, ...obj.input_data, id: obj.id,  name: obj.parcel_name };
          delete formated.input_data;

          return formated;

        });
      })
      .catch(err => { throw err });
  }

  getCCRecord(id) {
    return axios.get(`${URL.CC_RECORDS}${id}/`)
      .then(result => {
        let formated = { ...result.data, ...result.data.input_data, name: result.data.parcel_name };
        delete formated.input_data;

        return formated;

      })

      .catch(err => { throw err });
  }

  postCCRecords(model) {
    return axios.post(URL.CC_RECORDS, model)
      .then(result => result.data)
      .catch(err => { throw err });
  }

  updateCCRecord(model) {
    let {parcel_id, name, area, country, project_id, project_name } = model;
    let formated = {
      id: model.id,
      input_data: {...model},
      parcel_name: name,
      name,
      parcel_id,
      area,
      project_id,
      country,
      project_name

    }

    return axios.patch(`${URL.CC_RECORDS}${formated.id}/`, formated)
      .then(result => result.data)
      .catch(err => { throw err });

  }

  deleteCCRecord(id) {
    return axios.delete(`${URL.CC_RECORDS}${id}/`)
      .then(result => result.data)
      .catch(err => { throw err });
  }

  getCountryForParcel(parcel) {
    let coords = toLonLat(parcel.getCentroid());
    console.log(coords)
    return axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${coords[1]}&lon=${coords[0]}&format=json&zoom=3&limit=1`)
      .then(result => result.data.address)
      .catch(err => { throw err })
  }

  getEmissionFactorByCountry(country, fuel, method, factors = []) {
    let { irrigation_emmission_factor } = factors;
    let filtered = irrigation_emmission_factor.filter(obj => obj.region.indexOf(country.toUpperCase()) > -1);

    if (filtered.length === 0) {
      filtered = irrigation_emmission_factor.filter(obj => obj.region.indexOf('GLO') > -1 && obj.power_source === fuel && obj.method === method);
      return filtered[0].emission_factor;
    }

    filtered = filtered.filter(obj => obj.power_source === fuel && obj.method === method)

    return filtered[0].emission_factor;
  }

  calculateFuelEmissions(records, direct) {
    return records.reduce((acc, record) => {
      let factor = direct.filter(obj => obj.name === record.source)[0].emissions_factor;
      return parseFloat(acc) + parseFloat(record.consumption) * factor;
    }, 0);
  }

  calculateVolumeEmissions(events, area) {
    let sum = 0;

    events.map(model => {
      let { depth, percentage, factor } = model;

      let modelSum = (parseFloat(depth) * (percentage / 100) * area * 10 * factor);
      console.log(modelSum);

      sum += modelSum;
    })
    return sum;
  }

  calculateDepthDistanceEmissions(events, area) {

    let sum = 0;
    events.map(model => {

      let irrigationDepth = parseFloat(model.depth);
      let percentage = model.percentage;
      let energyFromTable = model.emission_factor;
      let distance = parseFloat(model.horizontalDistance);
      let boreholeDepth = parseFloat(model.boreholeDepth);
      let fuelFactor = model.factor;
      let conversionFactor = model.conversionFactor;
      let irrigationEnergy = energyFromTable + distance * 0.95 + boreholeDepth * 0.095;


      let totalEnergy = irrigationEnergy * irrigationDepth * (percentage / 100) * area;
      console.log('EF', totalEnergy * fuelFactor * (1 / conversionFactor));
      sum += (totalEnergy * fuelFactor * (1 / conversionFactor))

    });

    return sum

    // let { irrigationDepth, percentage, area, energyFromTable, distance, boreholeDepth, fuelFactor, conversionFactor } = model;

    // let irrigationEnergy = energyFromTable + distance * 0.95 + boreholeDepth * 0.095;
    // console.log('EF', irrigationEnergy);
    // console.log('irrigationDepth', irrigationDepth);
    // console.log('percentage', percentage);
    // console.log('area', area);

    // let totalEnergy = irrigationEnergy * irrigationDepth * (percentage / 100) * area;
    // console.log('TOTAL EF', totalEnergy);
    // console.log(conversionFactor)
    // console.log('FUEL FACTOR', fuelFactor);
    // return totalEnergy * fuelFactor * (1 / conversionFactor)

  }


  async pointQuery(lon, lat) {
    try {
      let tcdAndWW = await axios.post(URL.APPLICABILITY, { Lon: lon, Lat: lat });
      let corine = await axios.get(`https://api.opendatascience.eu/point-query/?property=lcv_landcover.clc_corine_c_100m_0..0cm_*_eumap_epsg3035_v2020.tif&lon=${lon}&lat=${lat}`)
      let corineObj = {};

      corine.data.map(obj => {
        corineObj[obj.year] = obj.label;
      })

      return {
        coordinates: `${lat.toFixed(4)}, ${lon.toFixed(4)}`,
        tcd: tcdAndWW.data.tcd[0],
        wetlands: tcdAndWW.data.ww[0],
        corine: corineObj,
        plough: tcdAndWW.data.plough[0],
        worldcover: tcdAndWW.data.world_cover[0],
        lc_uk: tcdAndWW.data.lc_uk[0]
      }

    } catch (error) {
      throw error.message;
    }
  }

  async monitoringQuery(lon, lat, parcel, project, user) {
    try {
      if (user?.user_type !== 1) {
        let result = await axios.get(URL.POINT.replace('__PROJECT__', project) + `${parcel}?lon=${lon}&lat=${lat}&source=s2&var=ndvi,ndvihres`);
        return {
          'NDVI': typeof result.data.NDVI === 'object' ? result.data.NDVI : null,
          'CHL': null,
          'BURN': null,
          'NDVI BACKGROUND': typeof result.data.NDVIHRES === 'object' ? result.data.NDVIHRES : null
        }
      } else {
        let result = await axios.post(URL.POINT_FARMER.replace('__ID__', parcel), { lat, lon, layer: ['ndvi', 'ndvihres'], source: 's2' });

        let ndvibg = null;

        try {
          ndvibg = Object.keys(result.data.ndvihres[0]).length > 0 ? result.data.ndvihres[0] : null;
        } catch (e) {

        }

        return {
          'NDVI': result.data.ndvi[0] ? result.data.ndvi[0] : null,
          'CHL': null,
          'BURN': null,
          'NDVI BACKGROUND': ndvibg
        }
      }


    } catch (error) {
      throw error.message;
    }
  }
}

export default new $data();