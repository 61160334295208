import { Box, CircularProgress, Container, Divider, Grid, Stack, Step, StepLabel, Stepper } from "@mui/material";
import WidgetTitle from "../widgets/WidgetTitle";
import WidgetDescription from "../widgets/WidgetDescription";
import { variables } from "../../styles/abstract/variables";
import { useContext, useEffect, useState } from "react";
import $data from "../../services/$data";
import FuelConsumption from "./FuelConsumption";
import FieldInformation from "./FieldInformation";
import IrrigationConsumption from "./IrrigationConsumption";
import Result from "./Result";
import {AppContext} from '../../AppContext';
import FertiliserPesticides from "./FertiliserPesticides";

function CarbonCalculator({isEdit, id}) {
  const {user} = useContext(AppContext);
  console.log(user)
  const [state, setState] = useState({
    estimatedFuelFactors: [],
    irrigationFactors: [],
    operationTypes: [],
    directFuelFactors: [],
    projects: [],
    step: 1,
  });
  
  const [fullModel, setFullModel] = useState({
    project_id: undefined,
    project_name: undefined,
    parcel_id: undefined,
    name: '',
    country: {},
    area: 0,
    year: 2025,
    crop: '',
    fuel: {
      isDirect: true,
      direct: [],
      estimated: [],
      totalEmission: 0
    },
    irrigation: {
      isVolumeOnly: true,
      volumeOnly: {
        method: undefined,
        depth: undefined,
        fuel: undefined,
        percentage: 80,
        factor: undefined,
        events: []
      },
      depthDistance: {
        method: undefined,
        fuel: undefined,
        emission_factor: undefined,

        horizontalConstant: 0.95,
        horizontalDistance: undefined,
        boreholeConstant: 0.095,
        boreholeDepth: undefined,
        
        percentage: 80,
        depth: undefined,

        fuelType: undefined,
        conversionFactor: 1,
        factor: undefined,
        events: []
      },  
      totalEmission: 0
    
    },
    fertiliser: []
  });



  const updateFuelFullModel = (newFuelModel) => {
    setFullModel(current => ({
      ...current,
      fuel: {
        ...current.fuel,
        ...newFuelModel
      }
    }))
  }

  const updateIrrigationFullModel = (newIrrigationFullModel) => {
    setFullModel(current => ({
      ...current,
      irrigation: {
        ...current.irrigation,
        ...newIrrigationFullModel
      }
    }))
  }

  const updateParcelFullModel = (newParcelModel) => {
    setFullModel(current => ({
      ...current,
      ...newParcelModel
    }));
  }

  const [irrigationModel, setIrrigationModel] = useState({
    
  });

  const [loading, setLoading] = useState({
    fuel: true,
    irrigation: true,
    parcels: true,
    editModel: isEdit
  })

  const getData = async () => {
    try {
      let fuelConstants = await $data.getFuelFactors();
      let irrigationConstants = await $data.getIrrigationFactors();
      // let recordsData = await $data.getCCRecords();
      let fertiliser = await $data.getFertiliserTables();
      console.log(fertiliser)
      let formatedFuelFactors = $data.formatFuelOperations(fuelConstants.estimated_energy_use_params);
      let parcels = [];
      let projects = [];
      if(user.user_type === 3) {
       
        if(!isEdit) {
          projects = await $data.getProjects('', 99999);
          parcels = await $data.getParcels(projects.results[0].id, false);
          setFullModel(current => ({...current, project_id: projects.results[0].id, project_name: projects.results[0].name}))
        }
      }
       else {
       parcels = await $data.getParcels('', true)
      }
      
      if(isEdit) {
        let editModel = await $data.getCCRecord(id);
        
        if(!user.user_type === 3) {
          projects = await $data.getProjects('', 99999);
          parcels = await $data.getParcels(editModel.project_id, false);
  
        }
        setFullModel({...editModel});
      }
      
      setState(current => ({
        ...current,
        irrigationFactors: irrigationConstants,
        estimatedFuelFactors: fuelConstants.estimated_energy_use_params,
        directFuelFactors: fuelConstants.direct_energy_use_params,
        fieldOperations: formatedFuelFactors.operations,
        ...fertiliser,
        parcels: parcels,
        projects: projects.results,
      }));

    } catch (error) {
      setState(current => ({...current,
        estimatedFuelFactors: [],
        directFuelFactors: [],        
        irrigationFactors: '',
        parcels: []
      }));
    } finally {
      setLoading({
        fuel: false,
        irrigation: false,
        parcels: false,
        isEdit: false
      })
    }

  }

  const getParcelsForProject = async (id) => {
    try {
      let parcels = await $data.getParcels(id, false);
      setState(current => ({...current, parcels}));
    } catch(e) {

    }
  }

  useEffect(() => {

    //get records
    //get fuel factors
    //get irrigation factors
    getData();
    return () => {

    }
  }, [])

  return (
    <Container sx={{ mt: '30px', pb: '30px' }}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <WidgetTitle text="Carbon Calculator" />
        </Grid>
        <Grid item xs={12}>
          <Stack className="only-laptop" direction="row" alignItems="center" justifyContent="space-between">
            <WidgetDescription text="" />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider color="primary" light style={{ background: variables.primary, height: '3px' }} />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={state.step - 1} alternativeLabel>
              <Step>
                <StepLabel sx={{'* span': {fontSize: '20px !important'}}}>Field Information</StepLabel>
              </Step>
              <Step>
                <StepLabel sx={{'* span': {fontSize: '20px !important'}}}>Fertiliser & Pesticides</StepLabel>
              </Step>
              <Step>
                <StepLabel sx={{'* span': {fontSize: '20px !important'}}}>Fuel & Energy Use</StepLabel>
              </Step>
              <Step>
                <StepLabel sx={{'* span': {fontSize: '20px !important'}}}>Irrigation Emissions</StepLabel>
              </Step>
              <Step>
                <StepLabel sx={{'* span': {fontSize: '20px !important'}}}>Result</StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Grid>
        {(loading.isEdit || loading.fuel || loading.parcels || loading.irrigation) && <Grid sx={{mt: '40px'}} item xs={12} textAlign="center"> <CircularProgress /> </Grid>}
       {!loading.isEdit && !loading.fuel && !loading.parcels && !loading.irrigation && <Grid item xs={12}>
          {state.step === 1 && <FieldInformation getParcelsForProject={getParcelsForProject} projects={state.projects} parcels={state.parcels} nextStep={() => setState(current => ({...current, step: 2}))} updateModel={updateParcelFullModel} model={fullModel} setModel={setFullModel} />}
          {state.step === 2 && <FertiliserPesticides area={fullModel.area} prevStep={() => setState(current => ({...current, step: 1}))} nextStep={() => setState(current => ({...current, step: 3}))} fertiliserProduction={state.fertiliserProduction} nitrificationInhibitors={state.nitrificationInhibitors} directUsage={state.directUsage} globalWarming={state.globalWarming} model={fullModel} setModel={setFullModel} />}
          {state.step === 3 && <FuelConsumption fieldOperations={state.fieldOperations} area={fullModel.area} prevStep={() => setState(current => ({...current, step: 2}))} nextStep={() => setState(current => ({...current, step: 4}))} direct={state.directFuelFactors} estimated={state.estimatedFuelFactors} model={fullModel.fuel} setModel={updateFuelFullModel}/>}
          {state.step === 4 && <IrrigationConsumption skip={fullModel.fuel.isDirect} countryCode={fullModel.country.country_code} country={fullModel.country.country} area={fullModel.area} prevStep={() => setState(current => ({...current, step: 3}))} direct={state.directFuelFactors} nextStep={() => setState(current => ({...current, step: 5}))} model={fullModel.irrigation} setModel={updateIrrigationFullModel} volumeOnly={state.irrigationFactors} />}
          {state.step === 5 && <Result isEdit={isEdit} model={fullModel} prevStep={() => setState(current => ({...current, step: 4}))}  direct={state.directFuelFactors} />}
        </Grid>}

      </Grid>

    </Container>
  )
}

export default CarbonCalculator;