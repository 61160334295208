import { renderToStaticMarkup } from "react-dom/server"

const wrapReportHtml = (content) => {
  return (
    `<!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8" />
        <style>
          * {
            font-family: sans-serif;
          }
          @page {
            @top-center {
              content: element(pageHeader);
            }
          }
          #pageHeader {
            position: running(pageHeader);
          }
        </style>
      </head>
      <body>
      ${renderToStaticMarkup(content)}
      </body>
    </html>
    `
  )
}

export default wrapReportHtml;
